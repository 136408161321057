import React, { useEffect, useState } from "react";
import sdk from "../sdk/sdk";
import { useCart } from "../components/Carts/useCart";
import { useAuth } from "../components/Auth/useAuth";
import Header from "../components/Headers/Header";
import Button from "../components/ui/Buttons";
import Text from "../components/ui/Text";
import { navigate } from "../routes";
import { Drawer } from "@material-ui/core";
import CurrentCart from "../components/Carts/CurrentCart";
import { Container, Row, Col } from "react-bootstrap";
import PLVCard from "../components/TesteursPLV/PLVCard";
import theme from "../components/ui/theme";
import { round2, price } from "../utils";
import { admin } from "../routes/admin";
import Page from "../components/Page/Page";
import useTranslations from "../components/Translations/useTranslations";
import BrandLogo from "../components/ui/BrandLogo";
import FSLoader from "../components/Loaders/FSLoader";

function PLVPage() {
  const CART_KEY_PLV = "PLV";

  const [{ user }] = useAuth();
  const [{ current_cart }, cartSDK] = useCart(user.id);
  const trl = useTranslations();

  const [cartOpened, isCartOpen] = useState(false);
  const [loading, isLoading] = useState(true);
  const [recommandations, setRecommandations] = useState([]);
  const [testeurs_plv, setTesteurs_plv] = useState([]);

  const cart_data = cartSDK.getCartData();
  const max_mt_plv_offert = cartSDK.getMaxMtPLVOffert(cart_data);
  const mt_plv_in_cart = Math.round(cart_data.cumul_plv_in_cart * 100) / 100;

  const _addPLVInCart = (_article, qte_commande) => {
    const products = current_cart.products;
    const PLV_CART = products[CART_KEY_PLV];
    const creator = user.id;
    const custom_remise = 0;

    const article = { ..._article, generique: {} };

    if (PLV_CART === undefined) {
      // First PLV_ARTICLE
      cartSDK.addInCart({
        creator,
        product: {
          [CART_KEY_PLV]: [{ article, qte_commande, custom_remise }],
        },
      });
    } else {
      const articleIndex = PLV_CART.findIndex(
        (art) => art.article.CODART === article.CODART
      );
      if (articleIndex >= 0) {
        if (qte_commande > 0) {
          // Article is already in cart
          PLV_CART[articleIndex] = { article, qte_commande, custom_remise };
          cartSDK.addInCart({
            creator,
            product: {
              [CART_KEY_PLV]: PLV_CART,
            },
          });
        } else {
          PLV_CART.splice(articleIndex, 1);
          cartSDK.addInCart({
            creator,
            product: {
              [CART_KEY_PLV]: PLV_CART,
            },
          });
        }
      } else {
        cartSDK.addInCart({
          creator,
          product: {
            [CART_KEY_PLV]: [
              ...PLV_CART,
              { article, qte_commande, custom_remise },
            ],
          },
        });
      }
    }
  };

  const _isInPLV_CART = (codart) =>
    current_cart.products[CART_KEY_PLV] &&
    current_cart.products[CART_KEY_PLV].findIndex(
      (art) => art.article.CODART === codart
    ) >= 0;

  let codarts = [];
  Object.keys(current_cart.products).map((key) => {
    if (current_cart.products[key]) {
      current_cart.products[key].map((art) => {
        codarts.push(art.article.CODART.toString());
      });
    }
  });

  useEffect(() => {
    isLoading(true);
    if (codarts) {
      console.log("current_cart", current_cart);
      const queryParam = [...codarts];
      queryParam.codcli = current_cart.client.CODCLI;
      sdk.products.associateProducts.getCollection(queryParam).then((data) => {
        if (data.success) {
          const testerPlv = data.payload.testeurs_plv
            .map((el) => {
              el.label = trl.data.message("ART", "CODART", el.CODART, "LIBART");
              return el;
            })
            .sort((a, b) => (a.label < b.label ? -1 : 1));
          setTesteurs_plv(testerPlv);
          setRecommandations(data.payload.recommandations);
        }
        isLoading(false);
      });
    }
  }, []);

  return (
    <Page>
      <Header
        fixed={true}
        left_pane={
          <div className="d-flex align-items-center">
            <BrandLogo />
            <Button
              size="mini"
              icon="arrow-left"
              onClick={() => navigate.goBack()}
            />
            <Text variant="title_2" className="ml-3">
              {current_cart.client.NOMCLI}
            </Text>
          </div>
        }
        right_pane={
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div
                style={{
                  background:
                    mt_plv_in_cart > max_mt_plv_offert
                      ? "fireBrick"
                      : "forestGreen",
                  color: "white",
                  padding: theme.spacing([0.25, 0.5]),
                }}
                className="mr-2"
              >
                <strong>
                  {price(round2(max_mt_plv_offert - mt_plv_in_cart))}
                </strong>
                €
              </div>
              <div
                style={{
                  background: "orange",
                  color: "white",
                  padding: theme.spacing([0.25, 0.5]),
                }}
              >
                <strong>{price(max_mt_plv_offert)}</strong>€
              </div>
            </div>
            <Button
              className="ml-2"
              size="mini"
              label={trl.message("CompleteOrderPage.finishOrderAction")}
              onClick={() => navigate.push(admin.FINISH_ORDER)}
            />
            <Button
              className="ml-2"
              size="mini"
              icon="shopping-cart"
              onClick={() => isCartOpen(true)}
            />
          </div>
        }
      />

      <Header />

      <Drawer
        anchor="right"
        open={cartOpened}
        onClose={() => isCartOpen(false)}
      >
        <CurrentCart
          disabled_plv_infos={true}
          closeCart={() => isCartOpen(false)}
        />
      </Drawer>

      <Container className="mt-2">
        {recommandations.length > 0 && (
          <>
            <Text as="p" variant="title">
              {trl.message("CompleteOrderPage.recommendations")}
            </Text>

            <Row className="mb-3">
              {recommandations.map((article_recommande) => {
                return (
                  <Col
                    key={article_recommande.CODART}
                    md={4}
                    xl={3}
                    className="mb-2"
                  >
                    <PLVCard
                      article={article_recommande}
                      PLV_CART={current_cart.products[CART_KEY_PLV]}
                      addInCart={_addPLVInCart}
                      inCart={_isInPLV_CART(article_recommande.CODART)}
                    />
                  </Col>
                );
              })}
            </Row>
          </>
        )}

        <Text as="p" variant="title">
          {trl.message("CompleteOrderPage.testersPLVProducts")}
        </Text>

        <Row className="mb-2">
          {loading && <FSLoader />}
          {testeurs_plv.filter(plv => plv.TESTEUR_ou_PLV.startsWith("PLV")).map((plv) => {
            return (
              <Col
                key={plv.CODART}
                md={4}
                xl={3}
                className="mb-2"
              >
                <PLVCard
                  article={plv}
                  PLV_CART={current_cart.products[CART_KEY_PLV]}
                  addInCart={_addPLVInCart}
                  inCart={_isInPLV_CART(plv.CODART)}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Page>
  );
}

export default PLVPage;
