export function getBaseUrl( baseUrl ) {
    const validUrl = new RegExp("^(http|https):\/\/(.*)$", 'gm');

    if (baseUrl.match(validUrl)) {
        return baseUrl;
    }

    return window.location.hostname + baseUrl;
}

export function createPayload(options, data) {
    return {
        options,
        data,
    };
}

String.prototype.finishWith = function( char ) {
    return this.substr(this.length - char.length) === char;
}