import { useAPIManager } from '../lib/APIManager';
import confs from '../../configurations';

const { URL_API } = confs;

const customOperations = {
    getFilters: {
        run: (params, req) => req.get(URL_API+'/articles_generiques/'+params.codfag+'/filters'),
        successed: (state, action) => ({criteres: action.payload}),
    },
    
};

export function useArticlesGeneriques() {

    const [articles_generiques, methods] = useAPIManager({
        baseUrl: URL_API,
        ressource: {
            name: 'articles_generiques',
            key: 'CODGEN',
        },
        defaultHeaders: {
            'Content-type': 'application/json',
            'Authorization': state => state.auth && 'Bearer '+state.auth.token,
        },
        customOperations,
    });

    return [articles_generiques, methods];
    
}