import { useState } from 'react';

export function useCriteresListManager () {

    const defaultCritere = {
        critere_1: null,
        critere_2: null,
        critere_3: null,
    };
    
    const [selectedCriteres, setSelectedCritere] = useState(defaultCritere);

    const _resetCritere = () => setSelectedCritere(defaultCritere);

    const selectCritere = (critere) => {

        setSelectedCritere({...selectedCriteres, ...critere});

        if (critere.critere_1 === selectedCriteres.critere_1) {
            setSelectedCritere({...selectedCriteres, critere_1: null});
        }
        if (critere.critere_2 === selectedCriteres.critere_2) {
            setSelectedCritere({...selectedCriteres, critere_2: null});
        }
        if (critere.critere_3 === selectedCriteres.critere_3) {
            setSelectedCritere({...selectedCriteres, critere_3: null});
        }
    };
    
    return [ selectedCriteres, selectCritere, _resetCritere ];
}