import React from 'react';
import theme from '../ui/theme';
import useTranslations from '../Translations/useTranslations';
import { useAuth } from '../Auth/useAuth';

function CriteresList ({ criteres, selectedCriteres, selectCritere }) {

    const [{user}] = useAuth();
    const trl = useTranslations();

    const style = {
        ul: {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            overflowY: 'scroll',
            position: 'relative',
            minWidth: 300,
            maxHeight: 400,
        },
        title: {
            padding: theme.spacing(1),
            position: 'sticky',
            top: 0,
            color: 'white',
            fontWeight: 'bold',
            background: theme.primary,
        },
        li: {
            marginLeft: theme.spacing(1),
            padding: theme.spacing(.5),
            display : 'flex',
            alignItems: 'center',
        },
        radio: {
            width: 15,
            height: 15,
            borderRadius: 50,
            background: '#eceff4',
            boxShadow: 'inset 1px 1px 3px #c5cbd1, inset -2px -2px 3px #ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        radio_selected: {
            width: 9,
            height: 9,
            borderRadius: 50,
            backgroundColor: theme.primary,
        },
    };

    if (!criteres) return null;

    return (
        <ul style={style.ul}>
            {
                criteres.critere_1 && 
                    <React.Fragment>
                        {/* Liste des criteres 1 */}
                        <li style={style.title}>
                            {trl.data.message('SOCIETES', 'CODSOC', user.data.societe, 'LABEL_CR1')}
                        </li>
                        {
                            criteres.critere_1.criteres.map(critere => (
                                <li style={style.li} key={critere.CODCR1} onClick={() => selectCritere({critere_1: critere.CODCR1})}>
                                    <div style={style.radio}>
                                        {
                                            selectedCriteres.critere_1 === critere.CODCR1 && <div style={style.radio_selected}></div>
                                        }
                                    </div>
                                    <span className='ml-2'>
                                        {trl.data.message('CR1', 'CODCR1', critere.CODCR1, 'LIBCR1')}    
                                    </span>
                                </li>
                            ))
                        }
                    </React.Fragment>
            }
            {
                criteres.critere_2 && 
                <React.Fragment>
                        {/* Liste des criteres 2 */}
                        <li style={style.title}>
                            {/* {criteres.critere_2.name} */}
                            {trl.data.message('SOCIETES', 'CODSOC', user.data.societe, 'LABEL_CR2')}
                        </li>
                        {
                            criteres.critere_2.criteres.map(critere => (
                                <li style={style.li} key={critere.CODCR2} onClick={() => selectCritere({critere_2: critere.CODCR2})}>
                                    <div style={style.radio}>
                                        {
                                            selectedCriteres.critere_2 === critere.CODCR2 && <div style={style.radio_selected}></div>
                                        }
                                    </div>
                                    <span className='ml-2'>
                                        {trl.data.message('CR2', 'CODCR2', critere.CODCR2, 'LIBCR2')}    
                                    </span>
                                </li>
                            ))
                        }
                    </React.Fragment>
            }
            {
                criteres.critere_3 && 
                <React.Fragment>
                        {/* Liste des criteres 3 */}
                        <li style={style.title}>
                            {trl.data.message('SOCIETES', 'CODSOC', user.data.societe, 'LABEL_CR3')}
                        </li>
                        {
                            criteres.critere_3.criteres.map(critere => (
                                <li style={style.li} key={critere.CODCR3} onClick={() => selectCritere({critere_3: critere.CODCR3})}>
                                    <div style={style.radio}>
                                        {
                                            selectedCriteres.critere_3 === critere.CODCR3 && <div style={style.radio_selected}></div>
                                        }
                                    </div>
                                    <span className='ml-2'>
                                        {trl.data.message('CR3', 'CODCR3', critere.CODCR3, 'LIBCR3')}
                                    </span>
                                </li>
                            ))
                        }
                    </React.Fragment>
            }
            {
                (!criteres.critere_1 && !criteres.critere_2 && !criteres.critere_3) &&
                    <div className='py-3 px-3 text-center'>
                        {trl.message('MainPage.Dialog.noFilters')}
                    </div>
            }
        </ul>
    );
}

export default CriteresList;