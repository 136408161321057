import { useSelector } from 'react-redux';
import { getBaseUrl } from './utils';
import { useMethods } from './useMethods';

// React hook for api collection managing
export function useAPIManager({ 
    constraints = { success: 'success', payload: 'payload', error: 'error' },
    baseUrl = '/api',
    ressource,
    reduxKeys = { root: 'ressources', collection: 'collection', item: 'item', filter_results: 'filter_results' },
    defaultHeaders,
    customOperations,
}) {
    // Clean the URL base
    const _baseUrl = getBaseUrl( baseUrl );

    // Extract ressource name and key
    const { name: ressourceName, key: ressourceKey } = ressource;

    const { root = 'ressources', collection = 'collection', item = 'item', filter_results = 'filter_results' } = reduxKeys;

    // Retrieve the state
    const state = useSelector( state => state );

    // Create payload Options
    const payloadOptions = { ressourceName, ressourceKey, collection, item, filter_results };

    //
    const buildMethods = useMethods(_baseUrl, { constraints, ressource, defaultHeaders, payloadOptions, customOperations, reduxKeys });
    
    return [ state[root][ressourceName] || {}, buildMethods ];
}

/*

    #####################
    Constraints
    #####################
    {
        successKey: '', // Required - Default 'success'
        payloadKey: ''  // L'intégralité de la data reçu si null -- Default 'payload'
        errorKey: ''    // Default error
    }
    
    
    #####################
    Ressource
    #####################
    {
        name: '', // Utilisé pour les appels apis, et pour clé de la ressource au sein du reducer. Si pas de s, à la fin, il sera pluraliser.
        key: ''   // Nom de la clé unique de la ressource
    }
    
    
    #####################
    Reduxkeys
    #####################

    ""  // La clé sous la quelle les ressources seront stockés, il convient d'injecter le Manager.Reducer avec la même clé.

    ou 

    {
        root: '', // La clé sous la quelle les ressources seront stockés, il convient d'injecter le Manager.Reducer avec la même clé.
        collection: '',
        item: '',
    }
    
    #####################
    defaultHeaders
    #####################
    // headers that will be added on auto generated request,
    {

    } 
    
    Regle pour toutes les méthodes autoGénérées: 
        Toutes les methodes exposées par le manager peuvent recevoir 2 parametres - TOUJOURS (donc y compris les customs actions):
        
        getCollection(params, calback)
        getItem(params, calback)
        postItem(params, calback)
        putItem(params, calback)
        deleteItem(params, calback)
        //
        anyCustomAction(params, calback)

        La callback est appelée les paramètres suivant: l'erreur (si il y en a une), et le payload.

        les embedded actions:

        customActions = {
            // Exemple avec un appel API
            nomDeMonAction: {
                run: (params, req) => req.post('http://blabla.fr', params), 
                sucessed: (state, action) => ({...state, my_custom_key: action}),
                failed: (state, msg) => state,
                dispatchOnly: (action) => ({ ...state, action.payload }),
            },
            // Ex avec uniquement mutation sur le state
            increment: {
                dispatchOnly: (state, action) => ({...state, counter: action.payload}),
            }
        };

        Cette custom action doit se traduire de la maniere suivante,

        const [ress, meths];

        meths.nomDeMonAction(params, (err, payload) => {
            console.log('My action has been achieved);
        });


*/