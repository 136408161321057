import React, { useContext, useEffect } from 'react';
import { useReducer } from 'reinspect';
import combinedReducer from './reducers';

const StoreContext = React.createContext();

export const SESSION_STATE_KEY = 'app.context.store.ef37bc9f1';

export const StoreProvider = ({ children }) => {
    // Init all states
    const memorizedState = JSON.parse(localStorage.getItem(SESSION_STATE_KEY));
    
    const initialState = memorizedState || combinedReducer(undefined, {type: '@@INIT'});
    // 
    const contextValue = useReducer(combinedReducer, initialState);

    useEffect(() => {
        localStorage.setItem(SESSION_STATE_KEY, JSON.stringify(contextValue[0]));
        console.log(contextValue);
        
    }, [contextValue]);
    
    return (
        <StoreContext.Provider value={contextValue}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = _function => {
    const [state, dispatch] = useContext(StoreContext);

    return [_function(state), dispatch];
};