import qs from "qs";
import { navigate } from "./routes";
import { auth } from "./routes/auth";
import { SESSION_STATE_KEY } from "./store";

function getToken() {
  const state = JSON.parse(localStorage.getItem(SESSION_STATE_KEY));
  let token = "";
  if (state && state.auth) {
    token = state.auth.token;
  }

  return token;
}

const AnswerHandler = (json) => {
  try {
    console.log(
      json,
      json.success === false && json.error_type === "EXPIRED_TOKEN",
      json.success,
      json.error_type
    );
    if (json.success === false && json.error_type === "EXPIRED_TOKEN") {
      window.location.href = auth.LOGIN.path;
    }
    return new Promise((resolve, reject) => resolve(json));
  } catch (e) {
    throw new Error(e.getMessage());
  }
};

export const request = {
  post: (url, params = {}, headers = {}) =>
    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        ...headers,
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler),
  get: (url, params = {}, headers = {}) =>
    fetch(url + "?" + qs.stringify(params), {
      method: "GET",
      data: qs.stringify(params),
      headers: {
        Authorization: "Bearer " + getToken(),
        ...headers,
      },
    }).then((data) => data.json()),
  delete: (url, params = {}, headers = {}) =>
    fetch(url, {
      method: "DELETE",
      data: qs.stringify(params),
      headers: {
        Authorization: "Bearer " + getToken(),
        ...headers,
      },
    }).then((data) => data.json()),
  postImage: (url, body, headers = {}) =>
    fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: "Bearer " + getToken(),
        ...headers,
      },
    }).then((data) => data.json()),
};
