import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { initializeStore } from './_store';
import 'antd/dist/antd.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import confs from './configurations';

const { PRIMARY } = confs;

console.log('PRIMARY:', PRIMARY);

const store = initializeStore();

const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY,
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
