import React from 'react';
import { Container } from 'react-bootstrap';
import theme from '../ui/theme';

function Header ({ right_pane, left_pane, fixed = false}) {

    const style = {
        header: {
            padding: theme.spacing([1, 0]),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 80,
        },
    };

    const getFixedStyle = () => ({
        position: 'sticky', 
        display: 'block',
        top: '0',
        width: '100%',
        background: 'white',
        zIndex: 1000,
        boxShadow: '0 0 5px rgba(0, 0, 0, .3)'
    });
    
    return (
        <div style={fixed ? getFixedStyle() : {}}>
            <Container>
                <header style={style.header}>
                    <div>
                        {left_pane}
                    </div>
                    <div>
                        {right_pane}
                    </div>
                </header>
            </Container>
        </div>
    );
}

export default Header;