export const INIT_RESSOURCE = '@INIT_RESSOURCE';

export const GET_COLLECTION_SUCCESS = '@getCollection\\successed';
export const GET_COLLECTION_FAILED = '@getCollection\\failed';

export const GET_ITEM_SUCCESS = '@getItem\\successed';
export const GET_ITEM_FAILED = '@getItem\\failed';

export const POST_ITEM_SUCCESS = '@postItem\\successed';
export const POST_ITEM_FAILED = '@postItem\\failed';

export const PUT_ITEM_SUCCESS = '@putItem\\successed';
export const PUT_ITEM_FAILED = '@putItem\\failed';

export const DELETE_ITEM_SUCCESS = '@deleteItem\\successed';
export const DELETE_ITEM_FAILED = '@deleteItem\\failed';

export const CUSTOM_OPERATION_SUCCESS = '@customOperation\\successed';
export const CUSTOM_OPERATION_FAILED = '@customOperation\\failed';
export const CUSTOM_OPERATION_DISPATCH_ONLY = '@customOperation\\dispatchOnly';

export const SELECT_ITEM = '@selectItem';
export const FILTER = '@filter';