import React from 'react';
import './App.css';
import './helpers';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import PrivateRoute from './components/Auth/PrivateRoute';
import NotFound from './pages/NotFound';
import history from './history';
import combinedRoutes from './routes';
import TranslationsLoader from './components/Translations/TranslationsLoader';
import { useAuth } from './components/Auth/useAuth';
import ConfigurationLoader from './components/Configuration/configurationLoader';

const generateRoutes = routes => routes.map((route, key) => (
  (route.private === false)
  ? <Route key={key} {...route} />
  : <PrivateRoute key={key} {...route}/>
));

function App() {

  const [{ user }] = useAuth();
  const defaultLang = window.navigator.language.slice(0, 2);

  return (
    <>
      <Router history={history}>

        <Switch>
          { generateRoutes(combinedRoutes) }
          <Route component={NotFound} />
        </Switch>
        
        <TranslationsLoader lang={(user && user.data.lang) || defaultLang} />
        <ConfigurationLoader/>

      </Router>
      <div>version 1.1.5</div>

    </>
  );
}

export default App;
