export const CREATE_CART = 'CREATE_CART';
export const CREATE_HEADER = '@createHeader/successed';
export const SELECT_CART = 'SELECT_CART';
export const ADD_IN_CART = '@addInCart\\successed';
export const REMOVE_CART = '@removeCart\\successed';
export const CUSTOM_REMISE = '@customRemise\\success';
export const CHANGE_FEET_REMISE = '@changeFeetRemise\\success';
export const REMOVE_ORDER_HEADER = '@removeOrderHeader\\success';
export const ADD_PACK_IN_CART = '@addPackInCart\\success';
export const REMOVE_PACK_FROM_CART = '@removePackFromCart\\success';
export const REMOVE_CART_FROM_CART_LIST = '@removeCartFromCartList\\success';
export const GENERATE_ORDER_SUCCESS = '@generateOrder\\success';
export const CHANGE_CART_HEADER = '@changeCartHeader';