import confs from '../../configurations';

const { PRIMARY } = confs;
// theme.js

const theme = {
    background: '#fff',
    primary: PRIMARY,
    neumorphicOutset: {
        background: '#fff',
        boxShadow: '0 0 5px #bdcac4',
        borderRadius: '.3rem',
    },
    neumorphicInset: {
        background: '#fff',
        boxShadow: 'inset 3px 3px 5px #c5cbd1, inset -3px -3px 5px #ffffff',
        borderRadius: '.3rem',
    },
    box_center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    spacing: ( space ) => {
        if (typeof space === 'number') return space+'rem';
        
        return space.map(sp => sp+'rem').join(' ');
    }
};

export default theme;