import { combineReducers } from 'redux';
import authReducer from './components/Auth/reducer';
import cartsReducer from './components/Carts/reducer';
import { APIManagerReducer } from './components/lib/APIManager'
import translationsReducer from './components/Translations/reducer';
import configurationReducer from "./components/Configuration/reducer";

export default combineReducers({
    auth: authReducer,
    carts: cartsReducer,
    ressources: APIManagerReducer,
    trl: translationsReducer,
    config: configurationReducer
});