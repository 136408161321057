import sdk from "../../sdk/sdk";
import { useDispatch } from "react-redux";
import { LOAD_CONFIGURATION_SUCCESS, IS_FETCHING } from "./actions";
import {useEffect} from 'react';

function ConfigurationLoader() {
  const dispatch = useDispatch();

  useEffect(() => {
  // Load translations
    dispatch({ type: IS_FETCHING, payload: true });

    sdk.me.configuration.get().then((configuration) => {
      dispatch({
        type: LOAD_CONFIGURATION_SUCCESS,
        payload: configuration.payload,
      });
      dispatch({ type: IS_FETCHING, payload: false });
    });
},[]);

  return null;
}

export default ConfigurationLoader;
