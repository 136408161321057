import React from 'react';
import CurrentCartInvalidModal from '../Dialogs/CurrentCartInvalidModal';

function Page({ children}) {
    
    return (
        <div>
            { children }
            <CurrentCartInvalidModal />
        </div>
    );
}

export default Page;