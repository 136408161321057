import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth/useAuth';
import theme from '../ui/theme';
import Text from '../ui/Text';
import useTranslations from '../Translations/useTranslations';
import { useCart } from '../Carts/useCart';
import { Container, Row, Col } from 'react-bootstrap';


const styles = {
    container: {
        backgroundColor: 'rgba(0, 0, 0, .05)',
        padding: theme.spacing(1.5),
        borderRadius: 5,
        marginBottom: theme.spacing(1.5)
    },
    textarea: {
        width: '100%',
        border: 'none',
        border: '1px solid rgba(0, 0, 0, .1)',
        borderRadius: 5,
    },
    input: {
        border: 'none',
        border: '1px solid rgba(0, 0, 0, .1)',
        borderRadius: 5,
        width: '100%',
    },
    title: {
        marginBottom: '.5rem',
    }
}

function ProspectRecap({ current_cart, remise, createHeader, removeOrderHeader, frais_port }) {

    const [{ user }] = useAuth();
    const [carts, cartSDK] = useCart(user.id);
    const { client, header } = current_cart;

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [refcliextension, setRefCliExtension] = useState("");

    const setRefCli = () => {
        cartSDK.changeHeader('REF_CLI',`Web ${lastName} ${firstName} ${new Date().getDate().toString().padStart(2,"0")}/${new Date().getMonth()} ${refcliextension}` );
    }

    const trl = useTranslations();

    useEffect(() => {
        const creator = user.id;
        removeOrderHeader({creator});
        createHeader({client, remise, frais_port, creator});
        cartSDK.changeHeader('PROSPECT', 'O')
        cartSDK.changeHeader('ROLE', 'VRP');
    }, [ ]);

    if (!header) return null;

    return (
        <div style={styles.container}>
            <Container fluid>
                <Row>
                    <Col md={6} xl={4}>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.firstnameLabel')}
                            <input style={styles.input} onChange={e => {
                                setFirstName(e.target.value);
                                cartSDK.changeHeader('PROSPECT_PRENOM', e.target.value);
                                setRefCli();
                            }} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.lastnameLabel')}
                            <input style={styles.input} onChange={e => {
                                setLastName(e.target.value);
                                cartSDK.changeHeader('PROSPECT_NOM', e.target.value);
                                setRefCli();
                            }} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.emailLabel')}
                            <input style={styles.input} onChange={e => cartSDK.changeHeader('PROSPECT_EMAIL', e.target.value)} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.deliveryAddressLabel')}
                            <input style={styles.input} onChange={e => cartSDK.changeHeader('PROSPECT_ADR_LIV', e.target.value)} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.billingAddressLabel')}
                            <input style={styles.input} onChange={e => cartSDK.changeHeader('PROSPECT_ADR_FAC', e.target.value)} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.billingAddressLabel')}
                            <input style={styles.input} onChange={e => cartSDK.changeHeader('PROSPECT_ADR_FAC', e.target.value)} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.siretLabel')}
                            <input style={styles.input} onChange={e => cartSDK.changeHeader('PROSPECT_SIRET', e.target.value)} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.tvaLabel')}
                            <input style={styles.input} onChange={e => cartSDK.changeHeader('PROSPECT_TVA', e.target.value)} />
                            </Text>
                        </p>
                        <p>
                            <Text as='p' style={styles.title} variant='body'>
                                {trl.message('FinishOrderPage.Prospect.deliveryDateLabel')}
                            <input style={styles.input} type='date' onChange={e => cartSDK.changeHeader('DATE_LIV', e.target.value)} />
                            </Text>
                        </p>
                        {/* <p>
                            <Text as='p' style={styles.title} variant='body'>
                            Mode de paiement
                            </Text>
                            <strong>{client.mode_paiement.LIBELLE}</strong>
                        </p> */}

                        
                           { user?.data?.cmd_facultative !== "O" && 
                        <div>  
                            <Text as='p' variant='body'>
                                Référence commande<br/>
                                {`Web ${lastName} ${firstName} ${new Date().getDate().toString().padStart(2,"0")}/${(new Date().getMonth() + 1).toString().padStart(2,'0')} ${new Date().getFullYear()} `}<input onChange={e => {
                                    setRefCliExtension(e.target.value);
                                    setRefCli();
                                }}></input>
                            </Text>
                        </div>

                            }

                        <p>
                            <label>
                                <Text as='p' style={styles.title} variant='boyd'>
                                    {trl.message('FinishOrderPage.Prospect.extraInformation')} <br/>
                                    <small>{trl.message('FinishOrderPage.Prospect.extraInformationDetails')}</small>
                                <textarea
                                    onChange={e => cartSDK.changeHeader('COMMENTAIRE_LIBRE', e.target.value)}
                                    style={styles.textarea}
                                    rows={3}
                                    ></textarea>
                                </Text>
                            </label>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ProspectRecap;