import * as actions from './actions';

const initialState = {
    configuration: null,
    isFetching: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                configuration: action.payload,
            };
            break;
        }
        case actions.IS_FETCHING: {
            return {
                ...state,
                isFetching: action.payload,
            };
            break;
        }
        default:
            return state;
            break;
    }
}