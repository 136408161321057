import React from 'react';
import confs from "../../configurations";


const { URL_GED } = confs;

const hideImg = () => {
    document.getElementById("HideImg")
    .style.display = "none";
}


const ClientLogo = ({userId}) => {
    const brandStyle = {
        height: '30px',
        width: 'auto',
       // 'margin-left': '5px',
    }

    return <img 
    className='ml-3'
    id="HideImg"
    style={brandStyle}
    src={URL_GED + "/Clients/" +userId + "/Logo/" + userId + "-e.jpg"}
    onError={({ currentTarget }) => {
        hideImg()
    }}
  />;
}

export default ClientLogo;