import { useSelector } from "react-redux";
import qs from "qs";
import { navigate } from "../../../routes";
import { auth } from "../../../routes/auth";

const AnswerHandler = (json) => {
  try {
    console.log(
      json,
      json.success === false && json.error_type === "EXPIRED_TOKEN",
      json.success,
      json.error_type
    );
    if (json.success === false && json.error_type === "EXPIRED_TOKEN") {
      window.location.href = auth.LOGIN.path;
    }
    return new Promise((resolve, reject) => resolve(json));
  } catch (e) {
    throw new Error(e.getMessage());
  }
};

export function useRequest(defaultHeaders) {
  const state = useSelector((state) => state);
  let realHeaders = {};

  for (let property in defaultHeaders) {
    if (defaultHeaders.hasOwnProperty(property)) {
      if (typeof defaultHeaders[property] === "function") {
        realHeaders[property] = defaultHeaders[property](state);
      } else {
        realHeaders[property] = defaultHeaders[property];
      }
    }
  }

  const request = {};

  request.get = (url, _params = {}, headers = {}) => {
    return fetch(url + "?" + qs.stringify(_params), {
      method: "GET",
      headers: {
        ...headers,
        ...realHeaders,
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler);
  };

  request.post = (url, _params = {}, headers = {}) =>
    fetch(url, {
      method: "POST",
      body: JSON.stringify(_params),
      headers: {
        ...headers,
        ...realHeaders,
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler);

  request.put = (url, _params = {}, headers = {}) =>
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(_params),
      headers: {
        ...headers,
        ...realHeaders,
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler);

  request.patch = (url, _params = {}, headers = {}) =>
    fetch(url, {
      method: "PATCH",
      body: JSON.stringify(_params),
      headers: {
        ...headers,
        ...realHeaders,
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler);

  request.delete = (url, _params = {}, headers = {}) =>
    fetch(url, {
      method: "DELETE",
      data: JSON.stringify(_params),
      headers: {
        ...headers,
        ...realHeaders,
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler);

  request.call = (url, options) =>
    fetch(url, {
      method: options.method.toUpperCase(),
      data: JSON.stringify(options.params),
      headers: {
        ...options.headers,
        ...realHeaders,
      },
    })
      .then((data) => data.json())
      .then(AnswerHandler);

  return request;
}
