import React from 'react';
import AuthForm from '../components/Auth/AuthForm';
import { useSelector } from 'react-redux';
import history from '../history';
import { Container, Row, Col } from 'react-bootstrap';

function Login(){
    const auth = useSelector(state => state.auth);

    if (auth.is_confirmed) history.replace('/');

    return (  
        <Container>
            <Row style={{height: 'calc(100vh - 1.5rem)'}} className='justify-content-center align-items-center'>
                <Col md={6} lg={4}>
                    <AuthForm />
                </Col>
            </Row>
        </Container>
    );
}
 
export default Login;