import { useSelector } from 'react-redux';

function useConfiguration () {

    const configuration = useSelector(state => state.config.configuration);
    const isFetching = useSelector(state => state.config.isFetching);
    
    const config = {
        // Get simple message base on id in translations
        get: property => {
            if (isFetching) return undefined;
            return configuration[property];
        },
       
    }

    return config;
}

export default useConfiguration;