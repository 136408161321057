import React, { useState } from "react";
import { auth } from "./api";
import { types } from "./reducer";
import { useDispatch } from "react-redux";
import history from "../../history";
import theme from "../ui/theme";
import Button from "../ui/Buttons";
import useTranslations from "../Translations/useTranslations";
import { Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { welcomeLogo as logo } from "../logo";

function AuthForm() {
  const trl = useTranslations();
  const [credentials, setCredentials] = useState({});
  const [is_logging, setIsLogging] = useState(false);
  const [showForgetInstructions, setShowForgetInstructions] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const _onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const login = (credentials) => {
    setIsLogging(true);
    auth.login(credentials).then((data) => {
      if (data.success === false) {
        dispatch({ type: types.LOGIN_FAILED });
        setIsLogging(false);
        setError(data.status_msg);
        return;
      }
      dispatch({ type: types.LOGIN_SUCCESS, payload: data.payload });
      setIsLogging(false);
      history.replace("/");
    });
  };

  const styles = {
    inputContainer: {
      ...theme.neumorphicOutset,
      borderRadius: 100,
      padding: theme.spacing(0.75),
    },
    input: {
      width: "100%",
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
    },
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="center">
        <img className="img-fluid" src={logo} />
      </Box>

      <Box mt={2}>
        <Typography variant="h6" align="center">
          {trl.message("LoginPage.title")}
        </Typography>
      </Box>

      <div className="my-5">
        <div className="mt-3" style={styles.inputContainer}>
          <input
            style={styles.input}
            onChange={(e) => _onChange(e)}
            name="email"
            type="text"
            placeholder={trl.message("LoginPage.loginInputPlaceholder")}
          />
        </div>

        <div className="mt-3" style={styles.inputContainer}>
          <input
            style={styles.input}
            onChange={(e) => _onChange(e)}
            name="password"
            type="password"
            placeholder={trl.message("LoginPage.passwordInputPlaceholder")}
          />
        </div>
      </div>

      <Button
        block
        onClick={() => login(credentials)}
        loading={is_logging}
        label={trl.message("LoginPage.loginAction")}
      />
      <Box>
        {!showForgetInstructions ? (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setShowForgetInstructions(true);
            }}
            href="#show-forget-password"
          >
            {trl.message("LoginPage.showForgetInstructionsLabel")}
          </Link>
        ) : (
          <Typography variant="body">
            {trl.message("LoginPage.forgetInstructions")}
          </Typography>
        )}
      </Box>
      {error && (
        <div
          style={{
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            backgroundColor: "#f4ecec",
          }}
        >
          {trl.message("LoginPage.invalidCredentialsError")}
        </div>
      )}
      {}
    </React.Fragment>
  );
}

export default AuthForm;
