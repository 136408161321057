import { request } from "../request";
import confs from "../configurations";

const { URL_API } = confs;

const sdk = {
  //
  me: {
    //
    translations: {
      get: (params) => request.get(URL_API + "/me/translations", params),
    },
    dataTranslations: {
      get: (params) => request.get(URL_API + "/me/data_translations", params),
    },
    configuration: {
      get: () => request.get(URL_API + "/me/configuration"),
    },
  },
  //
  products: {
    associateProducts: {
      getCollection: (codarts) =>
        request.get(URL_API + "/products/associate_products", codarts),
    },
  },
  //
  orders: {
    create: (order) => request.post(URL_API + "/orders/create", order),
  },
};

export default sdk;
