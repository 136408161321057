export function getPricesSteps(article, qte) {
  let nbUnit = article.article.PUAu_Vente || 1;
  let minIncrement = 1;
  switch (article.article.unite_de_facturation) {
    case "Pièce":
      minIncrement = 1;
      break;
    case "Paquet":
      minIncrement = article.article.CNbr_PIECES_Par_PAQUET || 1;
      break;
    case "Carton":
      minIncrement =
        (article.article.CNbr_PIECES_Par_PAQUET || 1) *
        article.article.CNbr_PAQUETS_Par_CARTON;
      break;
    case "Palette":
      minIncrement =
        (article.article.CNbr_PIECES_Par_PAQUET || 1) *
        (article.article.CNbr_PAQUETS_Par_CARTON || 1) *
        (article.article.CNbr_CARTONS_Par_PALETTE || 1);
      break;
    default:
      minIncrement = 1;
  }

  let pricesSteps = [
    {
      nbItem: 3 * nbUnit,
      price: Number.parseFloat(article.article.PUB),
      startDate: new Date("1600-01-01"),
    },
    {
      nbItem: 6 * nbUnit,
      price: Number.parseFloat(article.article.PUB),
      startDate: new Date("1600-01-01"),
    },
    {
      nbItem: 12 * nbUnit,
      price: Number.parseFloat(article.article.PUB),
      startDate: new Date("1600-01-01"),
    },
  ];

  if (article.prices) {
    pricesSteps = [];
    article.prices.forEach((el) => {
      const nb = Number.parseFloat(el.QTESEUIL) * nbUnit;
      let current = pricesSteps.find((step) => step.nbItem === nb);
      if (current && current.startDate > el.DTEDEB) {
        // keep current as newer than el;
        return;
      }
      if (!current) {
        current = {};
        pricesSteps.push(current);
      }
      current.startDate = el.DTEDEB;
      current.price = Number.parseFloat(el.PUB);
      current.nbItem = nb;
    });

    switch (article.article.unite_de_facturation) {
      case "Pièce":
        minIncrement = 1;
        break;
      case "Paquet":
        minIncrement = article.article.CNbr_PIECES_Par_PAQUET || 1;
        break;
      case "Carton":
        minIncrement =
          (article.article.CNbr_PIECES_Par_PAQUET || 1) *
          article.article.CNbr_PAQUETS_Par_CARTON;
        break;
      case "Palette":
        minIncrement =
          (article.article.CNbr_PIECES_Par_PAQUET || 1) *
          (article.article.CNbr_PAQUETS_Par_CARTON || 1) *
          (article.article.CNbr_CARTONS_Par_PALETTE || 1);
        break;
      default:
        minIncrement = 1;
    }
  }

  pricesSteps = pricesSteps.sort((a, b) => (a.nbItem < b.nbItem ? -1 : 1));

  let pricesStepsRef = [];
  let priceStepRef;
  if (article.pricesref) {
    const refs = Array.isArray(article.pricesref)
      ? article.pricesref
      : Object.values(article.pricesref);
    refs.forEach((el) => {
      const nb = Number.parseInt(el.QTESEUIL) * nbUnit;
      let current = pricesStepsRef.find((step) => step.nbItem === nb);
      if (current && current.startDate > el.DTEDEB) {
        // keep current as newer than el;
        return;
      }
      if (!current) {
        current = {};
        pricesStepsRef.push(current);
      }
      current.startDate = el.DTEDEB;
      current.price = Number.parseFloat(el.PUB);
      current.nbItem = nb;
    });
    pricesStepsRef = pricesStepsRef.sort((a, b) =>
      a.nbItem < b.nbItem ? -1 : 1
    );

    if (pricesStepsRef.length > 0) {
      let i = pricesStepsRef.length - 1;
      priceStepRef = pricesStepsRef[i];
      while (priceStepRef.nbItem > qte && i > 0) {
        i--;
        priceStepRef = pricesStepsRef[i];
      }
    }
  }

  let priceStep;

  if (pricesSteps.length > 0) {
    let i = pricesSteps.length - 1;
    priceStep = pricesSteps[i];

    while (priceStep.nbItem > qte && i > 0) {
      i--;
      priceStep = pricesSteps[i];
    }
  }

  return { pricesSteps, nbUnit, minIncrement, priceStep, priceStepRef };
}
