import { useSelector } from 'react-redux';

function useTranslations () {

    const translations = useSelector(state => state.trl.translations);
    const dataTranslations = useSelector(state => state.trl.dataTranslations);

    const isFetching = useSelector(state => state.trl.isFetching);
    
    const trl = {
        // Get simple message base on id in translations
        message: (id, values) => {
            if (!translations && isFetching) return '...';

            if (!translations) return 'Error translations loading'

            const translation = translations.find(translation => translation.id === id);

            if (!translation && isFetching) return '...';
            if (!translation) return id;

            return values 
                ?   translation.value.replace(/{{\s*(\w*)\s*}}/g, (fullMatch, group1) => {
                        return !!values[group1] ? values[group1] : '';
                    })
                :   translation.value;
        },
        // Get message for data based on, dataName in dataTranslations
        data: {
            message: (model, pk_model, pk_model_value, dataField) => {
                if (!dataTranslations && isFetching) return '...';
    
                if (!dataTranslations) return 'Error translations loading'
                
                const dataTranslation = dataTranslations.find(dataTranslation => {
                    return (
                        dataTranslation.model === model &&
                        dataTranslation.pk_model === pk_model &&
                        dataTranslation.pk_model_value === pk_model_value &&
                        dataTranslation.dataField === dataField
                    );
                });

                return dataTranslation?.value|| "";
            },
        }
    }

    return trl;
}

export default useTranslations;