import Main from '../pages/Main';
import NotFound from '../pages/NotFound';
import Test from '../pages/Test';

export const main = {
    HOME: {
        path: '/',
        exact: true,
        component: Main,
    },
    TEST: {
        path: '/test',
        exact: true,
        component: Test,
    },
    NOT_FOUND: {
        path: '/404',
        exact: true,
        component: NotFound,
    }
};