import React from 'react';
import theme from '../theme';
import './style.css';

function Button ({ label, children, icon, size = 'normal', onClick, loading, block, inverted, ...others }) {

    const createSpacing = (size) => {
        switch (size) {
            case 'mini':
                return theme.spacing([.5]); 
            case 'small':
                return theme.spacing([.75]); 
            case 'normal':
                return theme.spacing([1]);
            case 'large':
                return theme.spacing([1.25]); 
            case 'huge':
                return theme.spacing([1.5]); 
        }
    }

    const styles = {
        // ...theme.neumorphicOutset, 
        color: inverted ? theme.primary : 'white', 
        backgroundColor: inverted ? 'white' : theme.primary, 
        border: '1px solid ' + theme.primary, 
        padding: createSpacing(size),
        textTransform: 'uppercase',
        borderRadius: 3,
        fontSize: theme.spacing(.9),
        outline: 'none',
        width: block ? '100%' : 'auto',
        ...others.style,
    };

    const _icon = {
        width: 15,
        height: 15,
        fontSize: theme.spacing(1),
    };

    const getIcon = () => {
        if (loading) {
            if ((label||children) && !icon) {
                return (
                    <span className='mr-2'>
                        <svg fill={inverted ? theme.primary : 'white'} xmlns="http://www.w3.org/2000/svg" version="1.0" width="16px" height="16px" viewBox="0 0 128 128"><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" id="loader-svg" fillOpacity="1"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
                    </span>
                );
            }
            return <svg fill={inverted ? theme.primary : 'white'} xmlns="http://www.w3.org/2000/svg" version="1.0" width="16px" height="16px" viewBox="0 0 128 128"><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" id="loader-svg" fillOpacity="1"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>;
        }

        if (icon) return <i style={_icon} className={'fa fa-'+icon}></i>;

        return null;
    }
    
    return (
        <button {...others} onClick={onClick ? (e) => onClick(e) : null} style={styles} disabled={loading || others.disabled}>
            {getIcon()}
            {(icon && label||children) && <span className='ml-3'>{label||children}</span>}
            {(!icon && label||children) && label||children}
        </button>
    )
}

export default Button;