import React, { useState, useEffect } from "react";
import confs from "../../configurations";
import theme from "../ui/theme";
import { useCart } from "../Carts/useCart";
import { useAuth } from "../Auth/useAuth";
import Button from "../ui/Buttons";
import Text from "../ui/Text";
import { Dialog, Box } from "@material-ui/core";
import useTranslations from "../Translations/useTranslations";
import * as math from "mathjs";
import { getPricesSteps } from "../../utils_price";

const styles = {
  td: {
    padding: theme.spacing([1, 1.5]),
    textAlign: "center",
  },
};

const { URL_GED } = confs;

function ArticlesList({
  gen_articles,
  closeModal,
  selected_criteres,
  isPack = false,
}) {
  const [selectedArt, selectArt] = useState();
  const [auth] = useAuth();
  const trl = useTranslations();

  const [{ current_cart }, cartSDK] = useCart(auth.user.id);

  const [currentCart, setCurrentCart] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState(false);

  const libgen = trl.data.message(
    "GEN",
    "CODGEN",
    gen_articles.CODGEN,
    "LIBGEN"
  );

  const _addToLocalCart = (article, qte_commande) => {
    let _cart = currentCart;
    let index = _cart.findIndex((art) => art.article.CODART === article.CODART);

    let remise =
      article.REMISE_FIXE > 0
        ? article.REMISE_FIXE
        : article.groupe_tarifaire.REMISE_FIXE > 0
        ? article.groupe_tarifaire.REMISE_FIXE
        : null;

    if (!remise || (!!remise && remise <= 0)) {
      if (
        article.groupe_tarifaire.TRANCHE5 > 0 &&
        qte_commande >= article.groupe_tarifaire.TRANCHE5
      ) {
        remise = article.groupe_tarifaire.REMISE5;
      } else if (
        article.groupe_tarifaire.TRANCHE4 > 0 &&
        qte_commande >= article.groupe_tarifaire.TRANCHE4
      ) {
        remise = article.groupe_tarifaire.REMISE4;
      } else if (
        article.groupe_tarifaire.TRANCHE3 > 0 &&
        qte_commande >= article.groupe_tarifaire.TRANCHE3
      ) {
        remise = article.groupe_tarifaire.REMISE3;
      } else if (
        article.groupe_tarifaire.TRANCHE2 > 0 &&
        qte_commande >= article.groupe_tarifaire.TRANCHE2
      ) {
        remise = article.groupe_tarifaire.REMISE2;
      } else if (
        article.groupe_tarifaire.TRANCHE1 > 0 &&
        qte_commande >= article.groupe_tarifaire.TRANCHE1
      ) {
        remise = article.groupe_tarifaire.REMISE1;
      } else {
        remise = 0;
      }
    }

    console.log("REMISE", remise);

    const { pricesSteps, nbUnit, minIncrement, priceStep, priceStepRef } =
      getPricesSteps(article, qte_commande);

    const qte_unite_de_vente = qte_commande / (article.up_vte || 1);
    const price_applied = priceStep.price * qte_unite_de_vente;

    if (index >= 0) {
      // It already there
      if (qte_commande > 0) {
        _cart[index] = {
          article,
          qte_commande,
          custom_remise: remise,
          qte_unite_de_vente,
          price_applied,
          pub: priceStep.price,
        };
      } else {
        _cart.splice(index, 1);
      }
    } else {
      _cart.push({
        article,
        qte_commande,
        custom_remise: remise,
        qte_unite_de_vente,
        price_applied,
        pub: priceStep.price,
      });
    }

    setCurrentCart(_cart);

    cartSDK.addInCart({
      creator: auth.user.id,
      product: {
        [libgen]: _cart.sort((item1, item2) => {
          const item1Order = item1.article.ORDRE;
          const item2Order = item2.article.ORDRE;

          if (item1Order < item2Order) return -1;
          if (item1Order > item2Order) return 1;

          return 0;
        }),
      },
    });
  };

  const _addPack = (pack, qte_commande) => {
    // Pack in cart
    const packIndex = current_cart.packs.findIndex(
      (_pack) => _pack.name === libgen
    );
    const packs = current_cart.packs;

    if (qte_commande > 0) {
      // Update cart
      const currentPack = {
        name: libgen,
        qte_commande,
        mt_remise: pack.groupe_tarifaire.MT_REMISE,
      };

      if (packIndex >= 0) {
        packs[packIndex] = currentPack;
      } else {
        // The pack doesnt exist yet
        packs.push(currentPack);
      }
    } else {
      // Remove pack from cart
      packs.splice(packIndex, 1);
      cartSDK.addInCart({
        creator: auth.user.id,
        product: {
          [libgen]: [],
        },
      });

      return;
    }

    const packCart = pack.articles.map((art) => {
      let article = {
        ...art,
        ...art.article,
        groupe_tarifaire: pack.groupe_tarifaire,
        generique: pack,
        gen_in_cumul: pack.DANS_BASE_DE_CALCUL_CUMUL_CA_PLV,
      };

      // console.log('article', article);

      // return;

      let remise =
        article.REMISE_FIXE > 0
          ? article.REMISE_FIXE
          : article.groupe_tarifaire.REMISE_FIXE > 0
          ? article.groupe_tarifaire.REMISE_FIXE
          : null;

      if (!remise || remise <= 0) {
        if (
          article.groupe_tarifaire.TRANCHE5 > 0 &&
          article.QTE * qte_commande >= article.groupe_tarifaire.TRANCHE5
        ) {
          remise = article.groupe_tarifaire.REMISE5;
        } else if (
          article.groupe_tarifaire.TRANCHE4 > 0 &&
          article.QTE * qte_commande >= article.groupe_tarifaire.TRANCHE4
        ) {
          remise = article.groupe_tarifaire.REMISE4;
        } else if (
          article.groupe_tarifaire.TRANCHE3 > 0 &&
          article.QTE * qte_commande >= article.groupe_tarifaire.TRANCHE3
        ) {
          remise = article.groupe_tarifaire.REMISE3;
        } else if (
          article.groupe_tarifaire.TRANCHE2 > 0 &&
          article.QTE * qte_commande >= article.groupe_tarifaire.TRANCHE2
        ) {
          remise = article.groupe_tarifaire.REMISE2;
        } else if (
          article.groupe_tarifaire.TRANCHE1 > 0 &&
          article.QTE * qte_commande >= article.groupe_tarifaire.TRANCHE1
        ) {
          remise = article.groupe_tarifaire.REMISE1;
        } else {
          remise = 0;
        }

        console.log("remise", remise);
      }

      return {
        article,
        qte_commande: art.QTE * qte_commande,
        custom_remise: remise,
      };
    });

    cartSDK.addPackInCart({ creator: auth.user.id, packs });

    cartSDK.addInCart({
      creator: auth.user.id,
      product: {
        [libgen]: packCart,
      },
    });
  };

  useEffect(() => {
    if (
      gen_articles &&
      gen_articles.articles.length > 0 &&
      gen_articles.articles.filter(
        (a) =>
          a.article.valid === true &&
          (
            !a.article.LISTE_CODGRT_EXCLUS ||
            !a.article.LISTE_CODGRT_EXCLUS.split("¤").includes(
              "¤" + current_cart.client.CODGRT + "¤"
            ) ||
            isPack
          ).length > 0 &&
          currentCart.length === 0
      )
    ) {
      selectArt({
        ...gen_articles.articles[0],
        ...gen_articles.articles[0].article,
      });
      setCurrentCart(current_cart.products[libgen] || []);
    }
  }, [gen_articles]);

  if (!selectedArt)
    return (
      <div className="py-5 px-5">
        {trl.message("MainPage.Dialog.noProduct")}
      </div>
    );

  return (
    <div style={{ background: theme.background }}>
      <div
        style={{
          fontSize: "1.25rem",
          fontWeight: "bold",
          padding: theme.spacing(1),
          borderBottom: "1px solid rgba(0,0,0,.1)",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {trl.data.message("GEN", "CODGEN", gen_articles.CODGEN, "LIBGEN")}
          </div>
          <div className="d-flex flex-row-reverse">
            <Button
              className="ml-2"
              size="mini"
              icon="times"
              onClick={closeModal}
            />
            {gen_articles.groupe_tarifaire.TRANCHE1 > 0 && (
              <>
                <Button
                  className="ml-2"
                  size="mini"
                  inverted
                  icon="certificate"
                  onClick={() => setCertificateInfo(true)}
                />
                <Dialog
                  open={certificateInfo}
                  onClose={() => setCertificateInfo(false)}
                >
                  <div style={{ padding: theme.spacing(1.5) }}>
                    <table>
                      <thead
                        style={{
                          backgroundColor: theme.primary,
                          color: "white",
                        }}
                      >
                        <tr>
                          <td style={styles.td}>
                            {trl.message(
                              "MainPage.Dialog.GENGRTRules.thresholdQuantity"
                            )}
                          </td>
                          <td style={styles.td}>
                            {trl.message(
                              "MainPage.Dialog.GENGRTRules.rebateAmount"
                            )}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={styles.td}>
                            {gen_articles.groupe_tarifaire.TRANCHE1}
                          </td>
                          <td style={styles.td}>
                            {gen_articles.groupe_tarifaire.REMISE1}
                          </td>
                        </tr>
                        {gen_articles.groupe_tarifaire.TRANCHE2 > 0 && (
                          <tr>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.TRANCHE2}
                            </td>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.REMISE2}
                            </td>
                          </tr>
                        )}
                        {gen_articles.groupe_tarifaire.TRANCHE3 > 0 && (
                          <tr>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.TRANCHE3}
                            </td>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.REMISE3}
                            </td>
                          </tr>
                        )}
                        {gen_articles.groupe_tarifaire.TRANCHE4 > 0 && (
                          <tr>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.TRANCHE4}
                            </td>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.REMISE4}
                            </td>
                          </tr>
                        )}
                        {gen_articles.groupe_tarifaire.TRANCHE5 > 0 && (
                          <tr>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.TRANCHE5}
                            </td>
                            <td style={styles.td}>
                              {gen_articles.groupe_tarifaire.REMISE5}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Dialog>
              </>
            )}
            {gen_articles.DANS_BASE_DE_CALCUL_CUMUL_CA_PLV === "O" &&
              current_cart.client.groupe_tarifaire.MT_CDC_SEUIL1 > 0 && (
                <CalculatorRules />
              )}
            {/* {
                            gen_articles.REMISE_OUVERTE === 'O' && auth.user.role === 'VRP' && !isPack &&
                                <RemiseOpen />
                        } */}
          </div>
        </div>
        {!!gen_articles.OBSGEN && gen_articles.OBSGEN !== "" && (
          <Box mr={20}>
            <Text variant="subtitle_dark">
              {trl.data.message("GEN", "CODGEN", gen_articles.CODGEN, "OBSGEN")}
            </Text>
          </Box>
        )}
      </div>
      <div className="d-flex">
        <div className="px-4 py-4" style={{textAlign:"center"}}>
        <img 
              style={{
                maxWidth: "100%",
                maxHeight: "40vh",
                objectFit: "contain"
              }}
              src={URL_GED + "/Articles/" + selectedArt.CODART + "/" + selectedArt.CODART + "-e.jpg"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/background-picture-not-found-2.svg";
              }}
            />
          {/* <div
            style={{
              width: "100%",
              height: 250,
              backgroundImage: "url(/background-picture-not-found-2.svg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 250,
                backgroundImage:
                  "url(" +
                  URL_GED +
                  "/Articles/" +
                  selectedArt.CODART +
                  "/" +
                  selectedArt.CODART +
                  "-e.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div> */}
          <div>
            <Text style={{ width: 400 }} as="p" variant="title_2">
              {trl.data.message("ART", "CODART", selectedArt.CODART, "LIBART")}
            </Text>
            {selectedArt.DESCRIPTION}
          </div>
        </div>
        <div>
          <ul
            className="px-4 py-4 mb-0"
            style={{
              listStyleType: "none",
              maxHeight: 500,
              overflowY: "scroll",
              padding: theme.spacing(1),
            }}
          >
            {gen_articles &&
              gen_articles.articles
                .map((art, key) => {
                  if (isPack) return art;

                  if (selected_criteres && art) {
                    let hasCritere = false;
                    if (selected_criteres.critere_1) {
                      hasCritere = true;
                      const regexpCR1 = new RegExp(selected_criteres.critere_1);
                      if (art.article.CODCR1.match(regexpCR1)) return art;
                    }
                    if (selected_criteres.critere_2) {
                      hasCritere = true;
                      const regexpCR2 = new RegExp(selected_criteres.critere_2);
                      if (art.article.CODCR2.match(regexpCR2)) return art;
                    }
                    if (selected_criteres.critere_3) {
                      hasCritere = true;
                      const regexpCR3 = new RegExp(selected_criteres.critere_3);
                      if (art.article.CODCR3.match(regexpCR3)) return art;
                    }

                    if (
                      !hasCritere &&
                      !!art.article.valid &&
                      (!art.article.LISTE_CODGRT_EXCLUS ||
                        !art.article.LISTE_CODGRT_EXCLUS.split("¤").includes(
                          current_cart.client.CODGRT
                        ))
                    )
                      return art;
                  }
                })
                .map((art, key) => {
                  if (art) {
                    return (
                      <ArticleCard
                        key={key}
                        article={art}
                        groupe_tarifaire={gen_articles.groupe_tarifaire}
                        gen_in_cumul={
                          gen_articles.DANS_BASE_DE_CALCUL_CUMUL_CA_PLV
                        }
                        current_cart={currentCart}
                        addToLocalCart={_addToLocalCart}
                        selectArt={selectArt}
                        generique={gen_articles}
                        isPack={isPack}
                        selectedArt={selectedArt}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
          </ul>
        </div>
      </div>
      {isPack &&
        (() => {
          const currentPack = current_cart.packs.find(
            (pack) => pack.name === libgen
          );

          const qte = (currentPack && currentPack.qte_commande) || 0;

          return (
            <div
              className="d-flex justify-content-end"
              style={{
                borderTop: "1px solid rgba(0,0,0,.1)",
                padding: theme.spacing(1.5),
              }}
            >
              <div className="d-flex justify-content-between">
                {/* <div>
                                    <Button onClick={() => _addPack(gen_articles, 6)}
                                        label='6'
                                        className="mr-3"
                                        size='small'
                                        inverted
                                    />
                                    <Button onClick={() => _addPack(gen_articles, 9)}
                                        label='9'
                                        className="mr-3"
                                        size='small'
                                        inverted
                                    />
                                    <Button onClick={() => _addPack(gen_articles, 12)}
                                        label='12'
                                        size='small'
                                        inverted
                                    />
                                </div> */}
                <div className="d-flex ml-5">
                  <Button
                    onClick={() => _addPack(gen_articles, qte - 1)}
                    label="-"
                    size="small"
                  />
                  <strong className="d-flex align-items-center px-3">
                    {qte}
                  </strong>
                  <Button
                    onClick={() => _addPack(gen_articles, qte + 1)}
                    label="+"
                    size="small"
                  />
                </div>
              </div>
            </div>
          );
        })()}
    </div>
  );
}

const ArticleCard = ({
  article,
  current_cart,
  addToLocalCart,
  groupe_tarifaire,
  selectArt,
  gen_in_cumul,
  generique,
  selectedArt,
  isPack = false,
}) => {
  const articleAvailable =
    !article.prices ||
    (article.prices.length > 0 && !!article.article.unite_de_facturation);
  const trl = useTranslations();

  const _article = {
    ...article,
    ...article.article,
    groupe_tarifaire,
    gen_in_cumul,
    generique,
  };

  const artInCart =
    (current_cart &&
      current_cart.find((art) => art.article.CODART === _article.CODART)) ||
    {};

  const [qte, setQte] = useState(artInCart.qte_commande || 0);

  const changeQte = (qte) => {
    if (qte < 0) return;
    setQte(qte);
  };

  const style = {
    remise: {
      background: "orange",
      color: "white",
      fontSize: ".8rem",
      padding: theme.spacing([0.1, 0.25]),
      borderRadius: ".3em",
      fontWeight: "bold",
      marginRight: ".5rem",
    },
    testeur: {
      background: "fireBrick",
      color: "white",
      fontSize: ".8rem",
      padding: theme.spacing([0.1, 0.25]),
      borderRadius: ".3em",
      fontWeight: "bold",
      marginRight: ".5rem",
    },
  };

  const remise =
    _article.REMISE_FIXE > 0
      ? _article.REMISE_FIXE
      : _article.groupe_tarifaire.REMISE_FIXE > 0
      ? _article.groupe_tarifaire.REMISE_FIXE
      : artInCart && artInCart.custom_remise > 0
      ? artInCart.custom_remise
      : null;

  const { pricesSteps, nbUnit, minIncrement, priceStep, priceStepRef } =
    getPricesSteps(article, qte);

  let remiseCalculated = remise;

  if (article.prices?.length > 0 && priceStepRef) {
    remiseCalculated = Math.max(
      math.round((1 - priceStep.price / priceStepRef.price) * 100, 2),
      0
    );
  }

  if (article.prices?.length > 0 && !article.pricesref) {
    // if article and no prices ref => use is in net price => no remise displayed
    remiseCalculated = 0;
  }

  return (
    <li
      style={{
        backgroundColor:
          selectedArt.CODART === _article.CODART
            ? "rgba(0,0,0,.025)"
            : "transparent",
      }}
      onMouseEnter={() => selectArt(_article)}
    >
      <div>
        {_article.article.TESTEUR_ou_PLV === "TES" && (
          <span style={style.testeur}>
            {trl.message("MainPage.testerLabel")}
          </span>
        )}
        {remiseCalculated && articleAvailable && (
          <span style={style.remise}>-{remiseCalculated}%</span>
        )}
        {isPack && _article.QTE + " x "}
        {_article.CODART + "-"}
        {trl.data.message("ART", "CODART", _article.CODART, "LIBART")}
      </div>
      {articleAvailable && (
        <div style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
          {priceStep.price}€ pour {nbUnit} unité(s)
        </div>
      )}
      {!isPack && articleAvailable && (
        <div className="d-flex justify-content-between mt-2">
          <div>
            {" "}
            {pricesSteps.map((val, index, arr) => (
              <Button
                onClick={() => {
                  addToLocalCart(_article, val.nbItem);
                  changeQte(val.nbItem);
                }}
                key={val.nbItem + val.price}
                label={val.nbItem}
                className={index === arr.length ? "" : "mr-3"}
                size="small"
                inverted
              />
            ))}
          </div>
          <div className="d-flex ml-5">
            <Button
              onClick={() => {
                if (qte - minIncrement < 0) return;
                addToLocalCart(_article, qte - minIncrement);
                changeQte(qte - minIncrement);
              }}
              label="-"
              size="small"
            />
            <strong className="d-flex align-items-center px-3">
              {qte}
            </strong>
            <Button
              onClick={() => {
                addToLocalCart(_article, qte + minIncrement);
                changeQte(qte + minIncrement);
              }}
              label="+"
              size="small"
            />
          </div>
        </div>
      )}
      {!isPack && articleAvailable && (
        <div
          style={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "right" }}
        >
          ={" "}
          {(
            (((priceStep.price * qte) / nbUnit) * (100 - remiseCalculated)) /
            100
          ).toFixed(2)}
          €
        </div>
      )}
      {!isPack && !articleAvailable && (
        <div>
          <div>Aucun tarif négocié pour ce produit,</div>
          <div>veuillez nous contacter</div>
        </div>
      )}

      <hr />
    </li>
  );
};

const CalculatorRules = () => {
  const [{ user }] = useAuth();

  const trl = useTranslations();

  const [{ current_cart }] = useCart(user.id);

  const getRulesList = () => {
    return (
      <>
        {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL1 > 0 && (
          <table>
            <thead style={{ backgroundColor: theme.primary, color: "white" }}>
              <tr>
                <td style={styles.td}>
                  {trl.message("MainPage.Dialog.GRTRules.thresholdAmount")}
                </td>
                <td style={styles.td}>
                  {trl.message("MainPage.Dialog.GRTRules.rebateAmount")}
                </td>
              </tr>
            </thead>
            <tbody>
              {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL1 && (
                <tr>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL1}
                  </td>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.REMISE_PLV_TESTEUR1}
                  </td>
                </tr>
              )}
              {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL2 && (
                <tr>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL2}
                  </td>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.REMISE_PLV_TESTEUR2}
                  </td>
                </tr>
              )}
              {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL3 && (
                <tr>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL3}
                  </td>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.REMISE_PLV_TESTEUR3}
                  </td>
                </tr>
              )}
              {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL4 && (
                <tr>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.MT_CDC_SEUIL4}
                  </td>
                  <td style={styles.td}>
                    {current_cart.client.groupe_tarifaire.REMISE_PLV_TESTEUR4}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  const [calculatorModalOpen, isCalculatorModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        className="ml-2"
        onClick={() => isCalculatorModalOpen(true)}
        size="mini"
        inverted
        icon="calculator"
      />
      <Dialog
        open={calculatorModalOpen}
        onClose={() => isCalculatorModalOpen(false)}
      >
        <div style={{ padding: theme.spacing(1.5) }}>{getRulesList()}</div>
      </Dialog>
    </React.Fragment>
  );
};

const RemiseOpen = () => {
  const [modalOpened, isModalOpen] = useState(false);
  const trl = useTranslations();

  return (
    <React.Fragment>
      <Button
        className="ml-2"
        size="mini"
        inverted
        icon="percent"
        onClick={() => isModalOpen(true)}
      />
      <Dialog open={modalOpened} onClose={() => isModalOpen(false)}>
        <div style={{ padding: theme.spacing(1.5) }}>
          <Text>{trl.message("MainPage.Dialog.rebateOpen")}</Text>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ArticlesList;
