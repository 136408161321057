import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import moment from 'moment';
import { useAuth } from '../Auth/useAuth';
import { useCart } from '../Carts/useCart';
import Button from '../ui/Buttons';
import { main } from '../../routes/main';
import theme from '../ui/theme';
import { navigate } from '../../routes';
import useTranslations from '../Translations/useTranslations';

const style = {
    container: {
        padding: theme.spacing(1.5),
    },
    content: {
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    buttonBlock: {
        width: '100%',
    }
}

function CurrentCartInvalidModal() {

    const [{user}] = useAuth();
    const [{current_cart}, cartSDK] = useCart(user.id);

    const trl = useTranslations();
    
    const valid = !moment(current_cart.created_at).add(user.data.cart_validity || 1, 'days').isAfter(moment());
    
    const [open, isOpen] = useState(!!valid);
    return (
        <Dialog open={open}>
            
            <div style={style.container}>
                <div style={style.content}>
                    {trl.message('Dialog.invalidCart')}
                </div>
                <Button
                    style={style.buttonBlock}
                    onClick={() => {
                        navigate.push(main.HOME);
                        cartSDK.removeCartFromCartList({ creator: user.id, _id: current_cart._id});
                    }}
                    label={trl.message('Dialog.removeCartAction')}
                />
            </div>
            
        </Dialog>
    );
}

export default CurrentCartInvalidModal;