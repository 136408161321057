import * as actions from './actions';

const initialState = {};

export default function (state = initialState, action) {
    if (action.payload && action.payload.options) {
    }
    
    switch (action.type) {
        case actions.INIT_RESSOURCE: {
            let { ressourceName, collection, item } = action.payload.options;
            
            return {
                ...state,
                [ressourceName]: {
                    [collection]: [],
                    [item]: {},
                },
            }
            break;
        }
        case actions.GET_COLLECTION_SUCCESS: {
            let { ressourceName, collection, filter_results } = action.payload.options;
            
            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    [collection]: action.payload.data,
                    [filter_results]: action.payload.data,
                },
            }
            break;
        }
        case actions.SELECT_ITEM: {
            let { ressourceName, collection, item } = action.payload.options;
            
            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    [item]: state[ressourceName][collection][action.payload.data],
                },
            }
            break;
        }
        case actions.DELETE_ITEM_SUCCESS: {
            let { ressourceName, ressourceKey, collection, filter_results } = action.payload.options;
            
            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    [collection]: state[ressourceName][collection].filter(doc => doc[ressourceKey] !== action.payload.data[ressourceKey]),
                    [filter_results]: state[ressourceName][collection].filter(doc => doc[ressourceKey] !== action.payload.data[ressourceKey]),
                },
            }
            break;
        }
        // Get
        case actions.GET_ITEM_SUCCESS: {
            let { ressourceName, item } = action.payload.options;
            
            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    [item]: action.payload.data,
                },
            };
            break;
        }
        // Post
        case actions.POST_ITEM_SUCCESS: {
            let { ressourceName, collection, item, filter_results } = action.payload.options;
            
            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    [collection]: [...state[ressourceName][collection], action.payload.data],
                    [filter_results]: [...state[ressourceName][collection], action.payload.data],
                    [item]: action.payload.data,
                },
            };
            break;
        }
        // Put
        case actions.PUT_ITEM_SUCCESS: {
            let { ressourceName, ressourceKey, collection, item, filter_results } = action.payload.options;
            let IndexRessource = state[ressourceName].collection.find(doc => doc[ressourceKey] === action.payload.data[ressourceKey]);
            
            if (IndexRessource >= 0) {
                state[ressourceName][collection][IndexRessource] = action.payload.data;
                
                return {
                    ...state,
                    [ressourceName]: {
                        ...state[ressourceName],
                        [collection]: state[ressourceName][collection],
                        [filter_results]: state[ressourceName][collection],
                        [item]: action.payload.data,
                    },
                }
            }
        }
        // Post
        case actions.CUSTOM_OPERATION_SUCCESS: 
        case actions.CUSTOM_OPERATION_DISPATCH_ONLY: 
        {
            let { ressourceName } = action.payload.options;

            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    ...action.payload.data,
                },
            };
            break;
        }
        case actions.CUSTOM_OPERATION_FAILED: {
            let { ressourceName } = action.payload.options;

            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    ...action.payload.data,
                },
            };
            break;
        }
        case actions.FILTER: {
            let { ressourceName, filter_results } = action.payload.options;

            return {
                ...state,
                [ressourceName]: {
                    ...state[ressourceName],
                    [filter_results]: action.payload.data,
                },
            };
            break;
        }
        default: 
            return state;
            break;
    }
}