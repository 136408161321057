import theme from "./components/ui/theme";

const ids  = {
    PROGRESS_BAR_PAGE: 'ilsduc-progress-bar-page',
}

let progressLevel = 1;
let progressWidth = 1;
let interval = null;

export default {
    pogressBarPageShow: () => {
        progressLevel = 1;
        progressWidth = 1;
        let body = document.body;
        let progressContainer = document.createElement('div');
        let progress = document.createElement('div');
        progressContainer.style.position = 'absolute';
        progressContainer.style.top = 0;
        progressContainer.style.left = 0;
        progressContainer.style.width = '100%';
        progressContainer.style.height = '3px';
        progress.style.width = '0%';
        progress.style.height = '4px';
        progress.style.transition = 'all .1s ease-out';
        progress.style.background = theme.primary;
        progress.setAttribute('id', ids.PROGRESS_BAR_PAGE);
        progressContainer.append(progress);
        body.append(progressContainer);
    },
    startFakeLoad: () => {
        if (interval) {
            window.clearInterval(interval);
            progressLevel = 1;
            progressWidth = 1;
            interval = null;
            return;
        }
        interval = window.setInterval(() => {
            progressLevel++;
            const el = document.getElementById(ids.PROGRESS_BAR_PAGE);
            let newWidth = (1 - (1 / (1+progressLevel))) * 100;
            if (progressLevel < 20) {
                let _newWidth = newWidth - Math.random() * ((1 / (1+progressLevel)) * 100);
                if (_newWidth < progressWidth) return;
                newWidth = _newWidth;
            }
            progressWidth = newWidth;
            el.style.width = newWidth+'%';
        }, 500);
    },
    endFakeLoad: () => {
        window.clearInterval(interval);
        interval = null;
        const el = document.getElementById(ids.PROGRESS_BAR_PAGE);
        el.style.width = '100%';
        // el.remove();
        window.setTimeout(() => {
            el.remove();
        }, 400);
        return;
    },
};