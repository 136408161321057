import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { types } from './reducer';
import { auth } from './api';
import FSLoader from '../Loaders/FSLoader';
import { isConfirmed } from '../../routes';

function PrivateRoute(props){
    
    const authed = useSelector(state => state.auth);
    const [isFetching, setIsFetching ] = useState(true);
    const dispatch = useDispatch();

    const _isAllow = () => {
        if (props.role) {
            if (!((props.role.split('|')).findIndex(role => role === (authed.user && authed.user.role)) >= 0)) {
                return false;
            }
        }
        
        return true;
    }

    useEffect(() => {
        if (isConfirmed() === true) {
            return;
        }

        if(authed.token) {
            auth.confirm().then(data => {
                if(data.success === false) {
                    dispatch({type: types.CONFIRM_FAILED});
                    setIsFetching(false);
                    return;
                }
                dispatch({type: types.CONFIRM_SUCCESS});
                setIsFetching(false)
            });
        } else {
            setIsFetching(false);
        }
    }, []);

    if (isFetching && !isConfirmed()) return (
        <FSLoader fullHeight/>
    );

    if ( _isAllow() && authed.is_confirmed === true) return <Route {...props} />;

    return <Redirect to={{
                pathname: "/login",
                state: { from: window.location.pathname }
            }} 
        />
}
 
export default PrivateRoute;