import * as actions from './actions';
import { formatAddress, formatClientAddress } from '../Clients/clients';
import moment from 'moment';

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.CREATE_CART: {
            const newCart = {_id: Math.random(), created_at: moment(), client: action.payload.client, products: {}, packs: []};
            return {
                ...state,
                [action.payload.creator]: {
                    carts: [...(state[action.payload.creator]&&state[action.payload.creator].carts)||[], newCart],
                    current_cart: newCart,
                }
            };
            break;
        }
        case actions.CUSTOM_REMISE: {
            const indexArt = state[action.payload.creator].current_cart.products[action.payload.libgen].findIndex(art => art.article.CODART === action.payload.codart);
            if (indexArt >= 0) {
                state[action.payload.creator].current_cart.products[action.payload.libgen][indexArt].custom_remise = action.payload.remise;
            }

            return {...state};
        }
        break;
        case actions.ADD_IN_CART: {
            const newCurentCart = {
                ...state[action.payload.creator].current_cart,
                products: {...state[action.payload.creator].current_cart.products, ...action.payload.product},
            };
            const newCarts = [...state[action.payload.creator].carts.filter(cart => cart._id !== newCurentCart._id), newCurentCart];
            return {
                ...state,
                [action.payload.creator]: {
                    ...state[action.payload.creator],
                    carts: newCarts,
                    current_cart: newCurentCart,
                }
            }
            break;
        }
        case actions.SELECT_CART:
            return {
                ...state,
                [action.payload.creator]: {
                    ...state[action.payload.creator],
                    current_cart: state[action.payload.creator].carts.find(cart => cart._id === action.payload._id),
                }
            }
            break;
        case actions.REMOVE_CART:{
            const { creator, _id } = action.payload;
            
            return {
                ...state,
                [creator]: {
                    carts: state[creator].carts.filter(cart => cart._id !== _id),
                    current_cart: null,
                }
            }
            break;
        }
        case actions.REMOVE_CART_FROM_CART_LIST:{
            const { creator, _id } = action.payload;
            
            return {
                ...state,
                [creator]: {
                    carts: state[creator].carts.filter(cart => cart._id !== _id),
                    current_cart: null,
                }
            }
            break;
        }
        case actions.CREATE_HEADER: {
            const { client, creator, remise, frais_port } = action.payload; 

            let defaultAddrLiv;
            let defaultAddrFac;
            const addressesLivraison = [];
            const addressFacturation = [];

            const clientAddress = formatClientAddress(client);
            if (client.ADRFAC === "O") {
                addressFacturation.push({key: client.CODCLI, value: clientAddress})
            }
            if (client.ADRLIV === "O") {
                addressesLivraison.push({key: client.CODCLI, value: clientAddress})
            }
            if (client.ADRFAC_DFT === "O") {
                defaultAddrFac = client.CODCLI;
            }
            if (client.ADRLIV_DFT === "O" ) {
                defaultAddrLiv = client.CODCLI;
            }
            client.adresses.filter(el => el.ACTIF !== "N").forEach(element => {
                const addr = formatAddress(element);
                if (element.ADRFAC === "O") {
                    addressFacturation.push({key: element.CODADR, value: addr})
                }
                if (element.ADRLIV === "O") {
                    addressesLivraison.push({key: element.CODADR, value: addr})
                }
                if (element.ADRFAC_DFT === "O") {
                    defaultAddrFac = element.CODADR;
                }
                if (element.ADRLIV_DFT === "O" ) {
                    defaultAddrLiv = element.CODADR;
                }
            });

            defaultAddrFac = defaultAddrFac;
            defaultAddrLiv = defaultAddrLiv;


            const res = {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        header: {
                            clientName: client.NOMCLI,
                            PROSPECT: client.PROSPECT,
                            CODCLI: client.CODCLI,
                            CODADR_LIV: defaultAddrLiv,
                            CODADR_FAC: defaultAddrFac,
                            addresses: [{key: client.CODCLI, value: formatClientAddress(client)}, ...client.adresses.map(v => ({key: v.CODADR, value: formatAddress(v)}))],
                            addressesLivraison,
                            addressFacturation,
                            MT_REMISE: remise,
                            MT_PORT: frais_port,
                            COMMENTAIRE_LIBRE: '',
                            DATE_LIV: ""
                        },
                    }
                }
            }
            return res;
        }
        case actions.CHANGE_FEET_REMISE: {
            const { creator, remise } = action.payload; 
            return {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        header: {
                            ...state[creator].current_cart.header,
                            MT_REMISE: parseFloat(remise || 0),
                        },
                    }
                }
            }
        }
        case actions.REMOVE_ORDER_HEADER: {
            const { creator } = action.payload; 
            return {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        header: null,
                    }
                }
            }
        }
        case actions.ADD_PACK_IN_CART: {
            const { creator, packs } = action.payload; 
            return {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        packs
                    }
                }
            }
        }
        case actions.REMOVE_PACK_FROM_CART: {
            const { creator, packName } = action.payload; 
            return {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        packs: state[creator].current_cart.packs.filter(pack => pack.name !== packName),
                        products : {...state[creator].current_cart.products, [packName]: []},
                    }
                }
            }
        }
        case actions.GENERATE_ORDER_SUCCESS: {
            const { creator, generated_at, email } = action.payload; 
            return {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        email_receiver: email,
                        generated: true,
                        generated_at,
                    }
                }
            }
        }
        case actions.CHANGE_CART_HEADER: {
            const { creator, key, value } = action.payload; 
            return {
                ...state,
                [creator]: {
                    ...state[creator],
                    current_cart: {
                        ...state[creator].current_cart,
                        header: {
                            ...state[creator].current_cart.header,
                            [key]: value,
                        },
                    }
                }
            }
        }
        default:
            return state;
            break;
    }
}