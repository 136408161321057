import * as actions from './actions';

const initialState = {
    translations: null,
    dataTranslations: null,
    isFetching: false,
    lang: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_TRANSLATIONS_SUCCESS: {
            return {
                ...state,
                translations: action.payload,
            };
            break;
        }
        case actions.LOAD_DATA_TRANSLATIONS_SUCCESS: {
            return {
                ...state,
                dataTranslations: action.payload,
            };
            break;
        }
        case actions.CHANGE_LANG: {
            return {
                ...state,
                lang: action.payload,
            };
            break;
        }
        case actions.IS_FETCHING: {
            return {
                ...state,
                isFetching: action.payload,
            };
            break;
        }
        default:
            return state;
            break;
    }
}