import React, { useState } from 'react';
import { useAPIManager } from '../components/lib/APIManager';
import { useEffect } from 'react';
import confs from '../configurations';

const { URL_API } = confs;

function Test() {

    const [clients, clientsMethods] = useAPIManager({
        baseUrl: URL_API,
        ressource: {
            name: 'clients',
            key: 'CODCLI'
        },
        reduxKeys: {
            root: '_ressources',
        },
        defaultHeaders: {
            'Content-type': 'application/json',
            'Authorization': state => state.auth && 'Bearer '+state.auth.token,
        }
    })

    useEffect(() => {
        isLoading(true);
        clientsMethods.getCollection(null, () => isLoading(false));
    }, []);

    const [loading, isLoading] = useState(false);

    console.log(clients);
    
    return (
        <div>
            <ul>
                {
                    clients && clients.collection.map(client => (
                        <li>
                            {client.CODCLI}
                            <button onClick={() => clientsMethods.getItem(client.CODCLI, null, (err, doc) => console.log(doc))}>
                                {client.CODCLI}
                            </button>
                        </li>
                    ))
                }
            </ul>
            {
                loading && "Chargement en cours ..."
            }
        </div>
    );
}

export default Test;