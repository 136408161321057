import React from 'react';
import Header from '../components/Headers/Header';
import BrandLogo from '../components/ui/BrandLogo';
import { Container, Row, Col } from 'react-bootstrap';
import './pages.css';
import Button from '../components/ui/Buttons';
import Text from '../components/ui/Text';
import { navigate } from '../routes';
import { main } from '../routes/main';

class NotFound extends React.Component {
    render() { 
        return (
            <React.Fragment>
                <Header
                    fixed={true}
                    left_pane={(
                        <div className='d-flex align-items-center'>
                            <BrandLogo />
                        </div>
                    )}
                />
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={6}>
                            <div style={{minHeight: 'calc(100vh - 1.5rem)', width: '100%'}} className='d-flex justify-content-between align-items-center'>
                                <div style={{textAlign: 'center', width: '100%'}}>
                                    <h1 className='mb-5'>
                                        Oups, il n'y a rien à voir ici
                                    </h1>
                                    <p>
                                        <Button
                                            label="retourner à la page d'accueil"
                                            onClick={() => navigate.push(main.HOME)}
                                        />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
 
export default NotFound;