import React, {useState} from 'react';
import theme from '../ui/theme';
import {isMobile} from '../../utils';
import {useArticlesGeneriques} from './useArticlesGeneriques';
import { createRef } from 'react';

const SearchArticles = React.forwardRef(({ onChange=() => null, ...props }, ref) => {
    const [focused, isFocused] = useState(false);
    const [searching, isSearching] = useState(false);
    const reference = createRef()
  
    const [_, articlesGeneriquesMethods] = useArticlesGeneriques();

    const startSearch = () => reference.current.focus();
  
    return (
      <div
        className="d-flex align-items-center"
        style={{
          ...theme.neumorphicOutset,
          borderRadius: 100,
          padding: theme.spacing(0.75),
          cursor: focused || searching ? "normal" : "pointer",
        }}
        onClick={() => startSearch()}
      >
        <i className="fa fa-search mx-2"></i>
        <input
          {...props}
          onFocus={() => isFocused(true)}
          onBlur={() => isFocused(searching)}
          ref={reference}
          onChange={(e) => {
            articlesGeneriquesMethods.filterCollection(e.target.value, ["LIBGEN"]);
            onChange(e.target.value)}}
          type="search"
          style={{
            width: focused ? (isMobile() ? "105px" : "300px") : "105px",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            transition: "all .15s ease",
          }}
        />
      </div>
    );
  });

export default SearchArticles;