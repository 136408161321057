import React from 'react';
import {logo} from '../logo';
import { Link } from 'react-router-dom';
import { main } from '../../routes/main';

const BrandLogo = () => {
    const brandStyle = {
        height: '30px',
        width: 'auto',
    }

    return <Link to={main.HOME.path}><img src={logo} style={brandStyle} className='mr-3' /></Link>;
}

export default BrandLogo;