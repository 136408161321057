import React from 'react';
import theme from '../ui/theme';
import confs from '../../configurations';
import Button from '../ui/Buttons';
import useTranslations from '../Translations/useTranslations';

const { URL_GED } = confs;

function PLVCard({ article, addInCart, PLV_CART, inCart }) {
    
    const trl = useTranslations();

    const _article = PLV_CART && PLV_CART.find(art => art.article.CODART === article.CODART);

    let qte_commande = 0;

    if (_article)
    {
        qte_commande = _article.qte_commande;
    }
    
    return (
        <>
            <div className='mb-2' style={{...theme.neumorphicOutset, borderRadius: 3, height: '100%'}}>
                
                <div className='d-flex flex-column justify-content-between h-100'>
                    
                    <div>

                        <div className='py-2 px-2' style={{
                                height: 30, 
                                display:'flex', 
                                alignItems: 'center', 
                                justifyContent: 'space-between', 
                            }}
                        >
                            <div>
                                {
                                    inCart &&
                                    <strong style={{padding: theme.spacing([.1, .25]), borderRadius: '.1rem', fontSize: '.8rem', color: 'white', backgroundColor: theme.primary}}>{qte_commande}</strong>
                                }
                            </div>
                        </div>
                        
                        <div style={{
                                width: '100%', 
                                height: 250, 
                                backgroundImage: 'url('+URL_GED + '/Articles/' + article.CODART + '/' + article.CODART + '-e.jpg)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        ></div>
                        <div className='px-2 py-2'>
                            {article.CODART} - {trl.data.message('ART', 'CODART', article.CODART, 'LIBART')}
                            <div style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                                {article.PUB}€
                            </div>
                        </div>

                    </div>

                    {
                        article.why_recommandation &&
                            <div className='px-2 py-2'>
                                <div style={{color: 'forestGreen', fontSize: '.7rem'}}>
                                    {trl.message('CompleteOrderPage.Product.recommandation', {
                                        recommendedBy: article.why_recommandation,
                                    })}
                                </div>
                            </div>
                    }
                    
                    <div>

                        <div className='d-flex justify-content-center m-2'>
                            <Button onClick={() => {
                                    if (qte_commande-1 < 0) return;
                                    addInCart(article, qte_commande - 1);
                                }}
                                label='-'
                                size='small'
                            />
                            <strong className='d-flex align-items-center px-3'>{qte_commande}</strong>
                            <Button onClick={() => {
                                    if (article.TESTEUR_ou_PLV.startsWith("TES") && qte_commande === 1) {
                                        return;
                                    }
                                    addInCart(article, qte_commande + 1);
                                }}
                                label='+'
                                size='small'
                                style={{visibility:  article.TESTEUR_ou_PLV.startsWith("TES") && qte_commande === 1 ? 'hidden': 'visible'}}
                            />
                        </div>
                        {/* <div className='d-flex justify-content-center pb-2'>
                            <Button onClick={() => {
                                    addInCart(article, 6);
                                }}
                                label='6'
                                className="mr-3"
                                size='mini'
                                inverted
                            />
                            <Button onClick={() => {
                                    addInCart(article, 9);
                                }}
                                label='9'
                                className="mr-3"
                                size='mini'
                                inverted
                            />
                            <Button onClick={() => {
                                    addInCart(article, 12);
                                }}
                                label='12'
                                size='mini'
                                inverted
                            />
                        </div> */}

                    </div>

                </div>
                
            </div>
        </>
    )
}

export default PLVCard;