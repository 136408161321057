import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useArticlesGeneriques } from "./useArticlesGeneriques";
import FSLoader from "../Loaders/FSLoader";
import theme from "../ui/theme";
import Button from "../ui/Buttons";
import CriteresList from "./CriteresList";
import { useCriteresListManager } from "./useCriteresListManager";
import confs from "../../configurations";
import ArticlesList from "./ArticlesList";
import { useCart } from "../Carts/useCart";
import { useAuth } from "../Auth/useAuth";
import Dialog from "@material-ui/core/Dialog";
import useTranslations from "../Translations/useTranslations";
const { URL_GED } = confs;

function ArticlesGeneriques({ codfag, search_criteria }) {
  const [loading, isLoading] = useState(true);
  const [loadingFilters, isLoadingFilters] = useState(true);

  const [articles_generiques, articlesGeneriquesMethods] =
    useArticlesGeneriques();
  const [selected_criteres, selectCritere, resetCritere] =
    useCriteresListManager();

  const [articleModalOpen, setArticleModalOpen] = useState(false);
  const [critereModalOpen, setCritereModalOpen] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);

  const [selectedGen, setSelectedGen] = useState();

  const [auth] = useAuth();
  const [{ current_cart }] = useCart(auth.user.id);
  const trl = useTranslations();

  const selectGen = (gen) => {
    setLoadingItem(gen.CODGEN);
    articlesGeneriquesMethods.getItem(
      gen.CODGEN,
      { codcli: current_cart.client.CODCLI },
      (err, _gen) => {
        setSelectedGen(_gen);
        setArticleModalOpen(true);
        setLoadingItem(null);
      }
    );
  };

  useEffect(() => {
    isLoadingFilters(true);
    articlesGeneriquesMethods.getFilters({ codfag }, () => {
      isLoadingFilters(false);
      resetCritere();
    });
    isLoading(true);
    if (!search_criteria) {
      articlesGeneriquesMethods.getCollection(
        { codfag, codcli: current_cart.client.CODCLI },
        () => isLoading(false)
      );
    } else {
      articlesGeneriquesMethods.getCollection(
        { search_criteria, codcli: current_cart.client.CODCLI },
        () => isLoading(false)
      );
    }
  }, [codfag, search_criteria]);

  if (loading) return <FSLoader />;

  return (
    <React.Fragment>
      <Button
        size="mini"
        icon="filter"
        id="open-filters"
        style={{ display: "none" }}
        label={trl.message("MainPage.filtersAction")}
        onClick={() => setCritereModalOpen(true)}
        loading={loadingFilters}
      />
      <Container className="mb-4">
        {selected_criteres.critere_1 && (
          <span
            className="mr-2"
            style={{
              ...theme.neumorphicOutset,
              fontSize: ".9rem",
              padding: theme.spacing([0.25, 0.5]),
              borderRadius: 20,
            }}
          >
            <strong>
              {trl.data.message(
                "SOCIETES",
                "CODSOC",
                auth.user.data.societe,
                "LABEL_CR1"
              )}
            </strong>
            :{" "}
            {trl.data.message(
              "CR1",
              "CODCR1",
              selected_criteres.critere_1,
              "LIBCR1"
            )}
            <span
              className="ml-1"
              onClick={() =>
                selectCritere({ critere_1: selected_criteres.critere_1 })
              }
            >
              <i className="fa fa-times"></i>
            </span>
          </span>
        )}
        {selected_criteres.critere_2 && (
          <span
            className="mr-2"
            style={{
              ...theme.neumorphicOutset,
              fontSize: ".9rem",
              padding: theme.spacing([0.25, 0.5]),
              borderRadius: 20,
            }}
          >
            <strong>
              {trl.data.message(
                "SOCIETES",
                "CODSOC",
                auth.user.data.societe,
                "LABEL_CR2"
              )}
            </strong>
            :{" "}
            {trl.data.message(
              "CR2",
              "CODCR2",
              selected_criteres.critere_2,
              "LIBCR2"
            )}
            <span
              className="ml-1"
              onClick={() =>
                selectCritere({ critere_2: selected_criteres.critere_2 })
              }
            >
              <i className="fa fa-times"></i>
            </span>
          </span>
        )}
        {selected_criteres.critere_3 && (
          <span
            className="mr-2"
            style={{
              ...theme.neumorphicOutset,
              fontSize: ".9rem",
              padding: theme.spacing([0.25, 0.5]),
              borderRadius: 20,
            }}
          >
            <strong>
              {trl.data.message(
                "SOCIETES",
                "CODSOC",
                auth.user.data.societe,
                "LABEL_CR3"
              )}
            </strong>
            :{" "}
            {trl.data.message(
              "CR3",
              "CODCR3",
              selected_criteres.critere_3,
              "LIBCR3"
            )}
            <span
              className="ml-1"
              onClick={() =>
                selectCritere({ critere_3: selected_criteres.critere_3 })
              }
            >
              <i className="fa fa-times"></i>
            </span>
          </span>
        )}
      </Container>
      <Container>
        <Row className="mb-2">
          {articlesGeneriquesMethods.has("filter_results") &&
            articles_generiques.filter_results
              .filter((it) => {
                if (selected_criteres) {
                  let hasCritere = false;
                  if (selected_criteres.critere_1) {
                    hasCritere = true;
                    const regexpCR1 = new RegExp(selected_criteres.critere_1);
                    if (it.LISTE_CRITERES.match(regexpCR1)) return it;
                  }

                  if (selected_criteres.critere_2) {
                    hasCritere = true;
                    const regexpCR2 = new RegExp(selected_criteres.critere_2);
                    if (it.LISTE_CRITERES.match(regexpCR2)) return it;
                  }
                  if (selected_criteres.critere_3) {
                    hasCritere = true;
                    const regexpCR3 = new RegExp(selected_criteres.critere_3);
                    if (it.LISTE_CRITERES.match(regexpCR3)) return it;
                  }

                  if (!hasCritere) return it;
                }
              })
              .map((articleG, key) => {
                const LIBGEN = trl.data.message(
                  "GEN",
                  "CODGEN",
                  articleG.CODGEN,
                  "LIBGEN"
                );
                console.log(current_cart.products);
                return (
                  <ArticleGeneriqueCard
                    key={key}
                    articleG={articleG}
                    showArticles={() => selectGen(articleG)}
                    loading={loadingItem === articleG.CODGEN}
                    itemsInCart={
                      current_cart.products[LIBGEN] &&
                      current_cart.products[LIBGEN].reduce(
                        (a, b) => a + b.qte_commande,
                        0
                      )
                    }
                    isPack={articleG.TYPGEN === "PACK"}
                  />
                );
              })}
          {selectedGen && (
            <Dialog
              open={articleModalOpen}
              onClose={() => setArticleModalOpen(false)}
              maxWidth="md"
            >
              <ArticlesList
                gen_articles={selectedGen}
                selected_criteres={selected_criteres}
                isPack={selectedGen.TYPGEN === "PACK"}
                closeModal={() => setArticleModalOpen(false)}
              />
            </Dialog>
          )}
        </Row>
      </Container>
      {articlesGeneriquesMethods.has("criteres") && (
        <Dialog
          maxWidth={false}
          open={critereModalOpen}
          onClose={() => setCritereModalOpen(false)}
        >
          <CriteresList
            criteres={articles_generiques.criteres}
            selectedCriteres={selected_criteres}
            selectCritere={selectCritere}
          />
        </Dialog>
      )}
    </React.Fragment>
  );
}

const styles = {
  remise: {
    background: "orange",
    color: "white",
    fontSize: ".8rem",
    padding: theme.spacing([0.1, 0.25]),
    borderRadius: ".3em",
    fontWeight: "bold",
  },
  pack: {
    background: "forestGreen",
    color: "white",
    fontSize: ".8rem",
    padding: theme.spacing([0.1, 0.25]),
    borderRadius: ".3em",
    fontWeight: "bold",
  },
};

const ArticleGeneriqueCard = ({
  articleG,
  showArticles,
  loading,
  itemsInCart,
  isPack,
}) => {
  const [{ user }] = useAuth();
  const [{ current_cart }] = useCart(user.id);
  const trl = useTranslations();

  const packArt = current_cart.packs.find(
    (pack) =>
      pack.name === trl.data.message("GEN", "CODGEN", articleG.CODGEN, "LIBGEN")
  );

  return (
    <Col md={4} xl={3} className="mb-2">
      <div
        style={{ ...theme.neumorphicOutset, borderRadius: 3, height: "100%" }}
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <div
              className="py-2 px-2"
              style={{
                height: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                {itemsInCart > 0 && !isPack && (
                  <strong
                    style={{
                      padding: theme.spacing([0.1, 0.25]),
                      borderRadius: ".1rem",
                      fontSize: ".8rem",
                      color: "white",
                      backgroundColor: theme.primary,
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {itemsInCart}{" "}
                  </strong>
                )}
                {isPack && (
                  <>
                    {packArt && packArt.qte_commande > 0 && (
                      <strong
                        className="mr-2"
                        style={{
                          padding: theme.spacing([0.1, 0.25]),
                          borderRadius: ".1rem",
                          fontSize: ".8rem",
                          color: "white",
                          backgroundColor: theme.primary,
                          textAlign: "center",
                        }}
                      >
                        {packArt.qte_commande}
                      </strong>
                    )}
                    <span style={styles.pack}>PACK</span>
                  </>
                )}
              </div>
              <div>
                {/* {
                                    (!isPack && articleG.REMISE_OUVERTE === 'O' && user.role === 'VRP') && 
                                    <i style={{fontSize: '.8rem', color: theme.primary}} className='fa fa-percent ml-2'></i>
                                } */}
                {articleG.has_promotion && (
                  <i
                    style={{ fontSize: ".9rem", color: theme.primary }}
                    className="fa fa-certificate ml-2"
                  ></i>
                )}
                {articleG.DANS_BASE_DE_CALCUL_CUMUL_CA_PLV === "O" &&
                  current_cart.client.groupe_tarifaire.MT_CDC_SEUIL1 > 0 && (
                    <i
                      style={{ fontSize: ".9rem", color: theme.primary }}
                      className="fa fa-calculator ml-2"
                    ></i>
                  )}
                {!isPack && articleG.remise_fixe > 0 && (
                  <span className="ml-2" style={styles.remise}>
                    -{articleG.remise_fixe}%
                  </span>
                )}
                {isPack && articleG.mt_remise > 0 && (
                  <span className="ml-2" style={styles.remise}>
                    -{articleG.mt_remise}€
                  </span>
                )}
              </div>
            </div>
            <img 
              style={{
                width: "100%",
                height: 250,
              }}
              src={URL_GED +"/Generiques/" +articleG.CODGEN +"/" +articleG.CODGEN +"-e.jpg"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/background-picture-not-found-2.svg";
              }}
            />

            <div className="px-2 py-2">
              <span>
                {trl.data.message("GEN", "CODGEN", articleG.CODGEN, "LIBGEN")}
              </span>
            </div>
          </div>
          <div className="py-2 px-2">
            <Button
              size="small"
              inverted
              style={{ width: "100%" }}
              onClick={showArticles}
              loading={loading}
              label={trl.message("MainPage.showProductsAction")}
            />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ArticlesGeneriques;
