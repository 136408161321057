import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth/useAuth';
import { useCart } from '../Carts/useCart';
import { GenCartCard } from '../Carts/CurrentCart';
import { round2, price } from '../../utils';
import theme from '../ui/theme';
import ClientRecap from './ClientRecap';
import Button from '../ui/Buttons';
import { navigate } from '../../routes';
import { Dialog, FormControlLabel, Checkbox } from '@material-ui/core';
import Box from '../ui/Box';
import MUIBox from '@material-ui/core/Box';
import { main } from '../../routes/main';
import useTranslations from '../Translations/useTranslations';
import ProspectRecap from './ProspectRecap';
import confs from '../../configurations';

const { URL_GED } = confs;

const styles = {
    input: {
        border: 'none',
        fontSize: theme.spacing(1.15),
        color: 'fireBrick',
        fontWeight: 'bold'
    },
    manual_remise: {
        borderRadius: 3,
        color: 'fireBrick',
        fontWeight: 'bold'
    },
    modalContent: {
        textAlign: 'center',
    }
};

function OrderSynthese() {
    const [{ user }] = useAuth();
    const [{ current_cart }, cartSDK] = useCart(user.id);
    const [ creatingOrder, isCreatingOrder] = useState(false);
    const [ orderCompleted, isOrderComplete] = useState(false);
    const [ CGVValid, setCGVValid] = useState(false);
    const trl = useTranslations();

    const { client } = current_cart;

    const cart_data = cartSDK.getCartData();
    const max_mt_plv_offert = cartSDK.getMaxMtPLVOffert(cart_data);
    const mt_plv_in_cart = Math.round(cart_data.cumul_plv_in_cart * 100) / 100;

    const headerIsValid = () => {
        const { header } = current_cart;
        if (user.data.cmd_facultative !== "O" && (!header.REF_CLI || header.REF_CLI.indexOf("  ") > -1 || header.REF_CLI.endsWith(" "))) {
            return false;
        }
        if (client.PROSPECT === 'O') {
            return header.PROSPECT_PRENOM &&
                header.PROSPECT_NOM &&
                header.PROSPECT_EMAIL &&
                header.PROSPECT_ADR_LIV &&
                header.DATE_LIV &&
                CGVValid &&
                header.PROSPECT_SIRET;
        } else {
            return header.DATE_LIV && CGVValid && header.CODADR_LIV && header.CODADR_FAC;
        }
    }

    const getMtTotalHT = () => {
        const mt_max_offert = max_mt_plv_offert;
        const mt_total = cart_data.mt_total;
        const packs = current_cart.packs;

        const feet_remise = current_cart.header && current_cart.header.MT_REMISE;

        const toDeduct = packs.reduce((a, b) => a += b.mt_remise * b.qte_commande, 0);

        return round2(mt_total - feet_remise - toDeduct);
    }

    const getFraisPort = () => {
        const mt_port_base = client.has_commande ? client.groupe_tarifaire.MT_PORT2 : client.groupe_tarifaire.MT_PORT1;
        const franco_port = client.has_commande ? client.groupe_tarifaire.MT_FRANCO2 : client.groupe_tarifaire.MT_FRANCO1;

        const total_HT = getMtTotalHT();

        const frais_port = total_HT >= franco_port ? 0 : mt_port_base;

        return frais_port;
    }

    useEffect(() => {
        cartSDK.changeHeader('MT_PORT', getFraisPort());
    }, [ getFraisPort() ])

    const getTotalWithPort = () => round2(getMtTotalHT() + getFraisPort());

    const CGVLabel = <span>{trl.message('FinishOrderPage.CGVCheckboxText')} <a target='_blank' href={URL_GED+'/Edoc/'+current_cart.client.groupe_tarifaire.CODGRT+'/cgv'+current_cart.client.groupe_tarifaire.CODGRT+'.pdf'} download>{trl.message('FinishOrderPage.CGVDownloadLink')}</a> *</span>;
    
    const MT_PORT = getFraisPort();

    return (
        <div>
            <div className='mt-3'>
                {
                    client.PROSPECT !== 'O' 
                    ? 
                        (
                            <ClientRecap 
                                current_cart={current_cart}
                                createHeader={cartSDK.createHeader}
                                removeOrderHeader={cartSDK.removeOrderHeader}
                                remise={(mt_plv_in_cart >= max_mt_plv_offert) ? max_mt_plv_offert : mt_plv_in_cart}
                                frais_port={MT_PORT}
                            />
                        )
                    : 
                        (
                            <ProspectRecap 
                                current_cart={current_cart}
                                createHeader={cartSDK.createHeader}
                                removeOrderHeader={cartSDK.removeOrderHeader}
                                remise={(mt_plv_in_cart >= max_mt_plv_offert) ? max_mt_plv_offert : mt_plv_in_cart}
                                frais_port={MT_PORT}
                            />

                        )
                }
            </div>
            <div>
                {
                    Object.keys(current_cart.products).map(codgen => (
                        <GenCartCard 
                            key={codgen}
                            addInCart={cartSDK.addInCart} 
                            codgen={codgen} 
                            products={current_cart.products[codgen]} 
                            creator={user.id}
                            hideTools={true}
                            showPrice={true}
                        />
                    ))
                }

                <div className='d-flex justify-content-between align-items-center h-100'>
                    <div style={{marginLeft: theme.spacing(1), fontWeight: 'bold'}}>
                        {trl.message('FinishOrderPage.shippingFees')}
                    </div>
                    <div style={{fontSize: theme.spacing(1.15)}}>
                        <strong>{MT_PORT} <span style={{fontSize: '1rem', color: 'rgba(0, 0, 0, .7)'}}>€</span></strong>
                    </div>
                </div>
                <div>
                    {
                        (user.role === 'VRP') ? (
                            current_cart.header && (
                                <div style={styles.manual_remise} className='d-flex justify-content-between align-items-center'>
                                    <div style={{marginLeft: theme.spacing(1)}}>
                                        {trl.message('FinishOrderPage.rebateAmount')}
                                    </div>
                                    <label>
                                            <input style={styles.input} className='text-right' type='number' value={parseFloat(current_cart.header.MT_REMISE)} onChange={e => cartSDK.changeFeetRemise({creator: user.id, remise: e.target.value})}/>
                                            <i className='fa fa-euro'></i>
                                    </label>
                                </div>
                            )
                        ) : current_cart.header && (
                            <div style={styles.manual_remise} className='d-flex justify-content-between align-items-center'>
                                <div style={{marginLeft: theme.spacing(1)}}>
                                    {trl.message('FinishOrderPage.rebateAmount')}
                                </div>
                                <label>
                                        <span style={styles.input}>{parseFloat(current_cart.header.MT_REMISE)}</span>
                                        <i className='fa fa-euro'></i>
                                </label>
                            </div>
                        )
                    }
                </div>

                <hr />

                {/* Montant HORS TAXE */}
                <div className='d-flex justify-content-between align-items-center h-100'>
                    <div style={{marginLeft: theme.spacing(1), fontWeight: 'bold'}}>
                        {trl.message('FinishOrderPage.excludingTaxesAmount')}
                    </div>
                    <div style={{fontSize: theme.spacing(1.5), color: theme.primary}}>
                        <strong>{price(getTotalWithPort())} <span style={{fontSize: '1rem', color: 'rgba(0, 0, 0, .7)'}}>€</span></strong>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center h-100'>
                    <div style={{marginLeft: theme.spacing(1), fontWeight: 'bold'}}>
                        {trl.message('FinishOrderPage.valueAddedTaxAmount')}
                    </div>
                    <div style={{fontSize: theme.spacing(1.5)}}>
                        <strong>{price(round2(getTotalWithPort() * client.tva.TXTVA / 100))} <span style={{fontSize: '1rem', color: 'rgba(0, 0, 0, .7)'}}>€</span></strong>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center h-100'>
                    <div style={{marginLeft: theme.spacing(1), fontWeight: 'bold'}}>
                        {trl.message('FinishOrderPage.includingTaxesAmount')}
                    </div>
                    <div style={{fontSize: theme.spacing(1.5)}}>
                        <strong>{price(round2((getTotalWithPort() * client.tva.TXTVA / 100) + getTotalWithPort()))} <span style={{fontSize: '1rem', color: 'rgba(0, 0, 0, .7)'}}>€</span></strong>
                    </div>
                </div>

            </div>
            <div>
                <MUIBox paddingX={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={CGVValid}
                            onChange={(e, v) => setCGVValid(v)}
                            name="cgv"
                            color="primary"
                        />
                        }
                        label={CGVLabel}
                    />
                </MUIBox>
            </div>
            <div>
                <div>
                    <Button
                        style={{width: '100%'}}
                        icon='upload'
                        label={trl.message('FinishOrderPage.generateOrderAction')}
                        disabled={(getTotalWithPort() <= 0 || creatingOrder)}
                        loading={creatingOrder}
                        onClick={(e) => {
                            if (!headerIsValid()) {
                                alert(trl.message('FinishOrderPage.Dialog.missingInformation'));
                                return false;
                            }
                            isCreatingOrder(true);
                            cartSDK.generateOrder(user.id, (err, data) => {
                                isCreatingOrder(false);
                                console.log(err, data);
                                if (!err) {
                                    isOrderComplete(true);
                                }
                            });
                        }}
                    />
                </div>
            </div>
            <Dialog
                open={orderCompleted || (current_cart.generated === true)}
            >
                <Box p={1.5} jc='center'>
                    <div>
                        <div style={styles.modalContent}>
                            {trl.message('FinishOrderPage.generateOrderSuccessed', { buyerEmail: current_cart.email_receiver })}
                        </div>
                        <Box m={[1, 0, 0, 0]} jc='center'>
                            <Button
                                label={trl.message('FinishOrderPage.removeCartAndGoHome')}
                                icon='home'
                                onClick={() => {
                                    navigate.push(main.HOME);
                                    cartSDK.removeCartFromCartList({ creator: user.id, _id: current_cart._id});
                                }}
                            />
                        </Box>
                    </div>
                </Box>
            </Dialog>
        </div>
    );
}

export default OrderSynthese;