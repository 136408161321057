import React from 'react';
import Navigation from '../components/Headers/Navigation';
import confs from '../configurations';
import { useAPIManager } from '../components/lib/APIManager';
import { useEffect } from 'react';
import { navigate } from '../routes';
import { main } from '../routes/main';
import { admin } from '../routes/admin';
import { useState } from 'reinspect';
import FSLoader from '../components/Loaders/FSLoader';
import ArticlesGeneriques from '../components/Articles/ArticlesGeneriques';
import Page from '../components/Page/Page';

const { URL_API } = confs;

function AppNavigation(props) {

    const { critere } = props.match.params;

    const [ok, isOk] = useState(false);
    const [activeItem, setActiveItem] = useState();
    const [search_criteria, set_search_criteria] = useState();

    const [fags, fagsMethods] = useAPIManager({
        baseUrl: URL_API,
        ressource: {
            name: 'fags',
            key: 'CODFAG',
        },
        defaultHeaders: {
            'Content-type': 'application/json',
            'Authorization': state => state.auth && 'Bearer '+state.auth.token,
        },
    });
    
    useEffect(() => {
        fagsMethods.getCollection({testeurs_plvs: 'N'});
    }, []);

    useEffect(() => {
        if (fags.collection && critere) {
            if (critere === 'start') {
                navigate.replace(admin.APP_NAVIGATION, {critere: fags.collection[0].CODFAG});
            } else {
                // console.log('OK');
                let selectedItem = fags.collection.find(it => it.CODFAG === critere);
                if (!(selectedItem)) {
                    navigate.push(main.NOT_FOUND);
                    return;
                }
                _selectItem(selectedItem);
                isOk(true);
            }
        }
        
    }, [ fags.collection, critere ]);

    const _selectItem = (fag) => {
        setActiveItem(fag);
        if (fag.CODFAG !== critere) {
            navigate.push(admin.APP_NAVIGATION, { critere: fag.CODFAG });
        }
    };

    if (!ok) return <FSLoader fullHeight/>;
    
    return (
        <React.Fragment>
            <Navigation 
                items={fags.collection}
                activeItem={activeItem}
                selectItem={_selectItem}
                set_search_criteria={set_search_criteria}
            />
            <Page>
                <ArticlesGeneriques
                    codfag={activeItem.CODFAG}
                    search_criteria={search_criteria}
                />
            </Page>
        </React.Fragment>
    );
}

export default AppNavigation;