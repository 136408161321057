import { auth } from './auth';
import { main } from './main';
import { admin } from './admin';
import history from '../history';
import app from '../ilsduc';
import {auth as authApi} from '../components/Auth/api';

export const getPath = (routeName, params = {}) => {
    let error = false;
    routeName = routeName.replace(/:+(.\w*)/g, (match) => {
        for (let param in params) {
            if (params.hasOwnProperty(param)) {
                if (param === match.replace(':', '')) {
                    return params[param];
                } else {
                    error = true;
                }
            }
        }
    });  

    if (error === true) {
        history.push(main.NOT_FOUND.path);
        return;
    }

    return routeName;
};

let confirmed = false;

export const isConfirmed = () => confirmed;
export const setConfirmed = (_confirmed) => confirmed = _confirmed;

export const navigate = {
    push: (route, params = {}) => {
        if (route.private === true) { 
            app.pogressBarPageShow();
            app.startFakeLoad();
            authApi.confirm().then(data => {
                app.endFakeLoad();
                confirmed = data.success;
                let path = getPath(route.path, params);

                if (path !== undefined) history.push(path);
            });
        } else {
            let path = getPath(route.path, params);
            
            if (path !== undefined) history.push(path);
        }  
    },
    replace: (route, params = {}) => {
        app.pogressBarPageShow();
        app.startFakeLoad();
        authApi.confirm().then(data => {
            app.endFakeLoad();
            confirmed = data.success;
            history.replace(getPath(route.path, params));
        });
    },
    goBack: () => history.goBack(),
};

const combineRoutes = (multipleRoutes) => {
    let _routes = [];
    for (let routes in multipleRoutes) {
        for (let route in multipleRoutes[routes]) {
            _routes.push(multipleRoutes[routes][route]);
        }
    }
    return _routes;
}

export const routes = {
    auth,
    main,
    admin,
};

export default combineRoutes(routes).map(r => ({...r, exact: (r.exact ? r.exact : true)}));

