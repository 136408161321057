import React from "react";
import theme from "../ui/theme";

function FSLoader({ fullHeight }) {
  return (
    <div
      style={{
        width: "100%",
        height: fullHeight ? "100vh" : "100%",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          ...theme.neumorphicInset,
          borderRadius: "50px",
          width: 40,
          height: 40,
        }}
      >
        <Loader />
      </div>
    </div>
  );
}

const Loader = () => (
  <svg
    style={{ fill: theme.primary }}
    version="1.0"
    viewBox="0 0 128 128"
    space="preserve"
  >
    <g>
      <path
        d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
        id="loader-svg"
        fillOpacity="1"
      />
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 64 64"
        to="360 64 64"
        dur="1000ms"
        repeatCount="indefinite"
      ></animateTransform>
    </g>
  </svg>
);

export default FSLoader;
