import { useEffect } from 'react';
import sdk from '../../sdk/sdk';
import { useDispatch } from 'react-redux';
import { CHANGE_LANG, LOAD_TRANSLATIONS_SUCCESS, LOAD_DATA_TRANSLATIONS_SUCCESS, IS_FETCHING } from './actions';

function TranslationsLoader ({ lang }) {

    const dispatch = useDispatch();
    
    // Load translations
    useEffect(() => {
        if (!lang) return;

        dispatch({ type: CHANGE_LANG, payload: lang });
        dispatch({ type: IS_FETCHING, payload: true });

        Promise.all([
            
                sdk.me.translations.get({ lang }),
                sdk.me.dataTranslations.get({ lang }),
                
        ]).then( ([translations, dataTranslations]) => {

            dispatch({ type: LOAD_TRANSLATIONS_SUCCESS, payload: translations.payload });
            dispatch({ type: LOAD_DATA_TRANSLATIONS_SUCCESS, payload: dataTranslations.payload });
            dispatch({ type: IS_FETCHING, payload: false });

        })
    }, [ lang ]); // Reload on lang change

    return null;
}

export default TranslationsLoader;