import './utils';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './actions';
import { createPayload } from './utils';
import { useRequest } from './useRequest';
import _ from 'lodash';

export function useMethods(baseUrl, options) {

    const { constraints, ressource, defaultHeaders, payloadOptions, customOperations, reduxKeys } = options;

    const { success, payload, error } = constraints;
    const { name: ressourceName } = ressource;
    const { root, collection } = reduxKeys;
    //
    const dispatch = useDispatch();
    //
    const localState = useSelector( state => state[root][ressourceName] );
    // Pluralize ressource name
    if (!(ressourceName.finishWith('s'))) ressourceName = ressourceName + 's';

    const baseEndpoint = baseUrl + '/' + ressourceName;
    
    const request = useRequest( defaultHeaders );
    
    let methods = {};

    methods.getCollection = (params = {}, callback = () => null) => {
        return request.get(baseEndpoint, params)
        .then(JSONResponse => {
            if (JSONResponse[success] === true) {
                    dispatch({ type: actions.GET_COLLECTION_SUCCESS, payload: createPayload(payloadOptions, JSONResponse[payload]) });
                    callback(null, JSONResponse[payload]);
                } else {
                    dispatch({ type: actions.GET_COLLECTION_FAILED });
                    callback(JSONResponse[error]||'No error specified', null);
                }
            }).catch(err => callback(err, null));
    }

    methods.postItem = (params, callback = () => null) => {
        return request.post(baseEndpoint, params)
            .then(JSONResponse => {
                if (JSONResponse[success] === true) {
                    dispatch({ type: actions.POST_ITEM_SUCCESS, payload: createPayload(payloadOptions, JSONResponse[payload]) });
                    callback(null, JSONResponse[payload]);
                } else {
                    dispatch({ type: actions.POST_ITEM_FAILED });
                    callback(JSONResponse[error]||'No error specified', null);
                }
            }).catch(err => callback(err, null));
    }

    methods.getItem = (key, _params, callback = () => null) => {
        return request.get(baseEndpoint + '/' + key, _params)
        .then(JSONResponse => {
            if (JSONResponse[success] === true) {
                    dispatch({ type: actions.GET_ITEM_SUCCESS, payload: createPayload(payloadOptions, JSONResponse[payload]) });
                    callback(null, JSONResponse[payload]);
                } else {
                    dispatch({ type: actions.GET_ITEM_FAILED });
                    callback(JSONResponse[error]||'No error specified', null);
                }
            }).catch(err => callback(err, null));
    }

    methods.putItem = (key, _params, callback = () => null) => {
        return request.post(baseEndpoint + '/' + key, _params)
            .then(JSONResponse => {
                if (JSONResponse[success] === true) {
                    dispatch({ type: actions.PUT_ITEM_SUCCESS, payload: createPayload(payloadOptions, JSONResponse[payload]) });
                    callback(null, JSONResponse[payload]);
                } else {
                    dispatch({ type: actions.PUT_ITEM_FAILED });
                    callback(JSONResponse[error]||'No error specified', null);  
                }
            }).catch(err => callback(err, null));
    }

    methods.deleteItem = (key, _params, callback = () => null) => {
        return request.post(baseEndpoint + '/' + key, _params)
            .then(JSONResponse => {
                if (JSONResponse[success] === true) {
                    dispatch({ type: actions.DELETE_ITEM_SUCCESS, payload: createPayload(payloadOptions, JSONResponse[payload]) });
                    callback(null, JSONResponse[payload]);
                } else {
                    dispatch({ type: actions.DELETE_ITEM_FAILED });
                    callback(JSONResponse[error]||'No error specified', null);
                }
            }).catch(err => callback(err, null));
    }

    methods.selectItem = (itemKeyValue, callback = () => null) => {
        dispatch({ type: actions.SELECT_ITEM, payload: createPayload(payloadOptions, itemKeyValue)});
        callback(null, itemKeyValue);
    }

    methods.filterCollection = (keyWord, onKeys = [], callback = () => null) => {
        
        const _collection = localState && localState[collection];
        if (!keyWord || keyWord === '') {
            dispatch({ type: actions.FILTER, payload: createPayload(payloadOptions, _collection )});
            return;
        };

        const deburredKeyWord = _.deburr(keyWord);
        const regexp = new RegExp(deburredKeyWord, 'ig');

        let newCollection = [];
        _collection && _collection.forEach(item => {
            onKeys.some(key => {
                if (item[key] !== undefined) {
                    const deburredItem = _.deburr(item[key]);
                    if (item[key] && (deburredItem.match(regexp))) {
                        newCollection.push(item);
                        return true
                    }
                } else {
                    console.warn('The key '+key+' passed to filterCollection method doesnt exists on '+ressourceName+' item collection.');
                }
            });
        });
        
        dispatch({ type: actions.FILTER, payload: createPayload(payloadOptions, newCollection )});

        callback(null, newCollection);
    }

    // helper
    methods.isReady = () => {
        return localState !== undefined;
    }
    
    methods.has = key => {
        return methods.isReady && localState[key] !== undefined;
    }

    let _customOperations = {};

    for (let operationName in customOperations) {
        if (customOperations.hasOwnProperty(operationName)) {
            //
            if (customOperations[operationName].dispatchOnly) {
                // Dispatch Only actions
                _customOperations[operationName] = (params, callback = () => null) => {
                    try {
                        dispatch({ type: actions.CUSTOM_OPERATION_DISPATCH_ONLY, payload: createPayload(payloadOptions, customOperations[operationName].dispatchOnly(localState, {
                            type: actions.CUSTOM_OPERATION_DISPATCH_ONLY,
                            payload: params,
                        }))});
                        callback(null, params);
                    } catch (error) {
                        callback(error, null)
                    }
                }
                continue;
            }
            // Request actions
            if (customOperations[operationName].run && customOperations[operationName].successed) {
                // Our custom operations has all things that he needs
                _customOperations[operationName] = (params, callback = () => null) => {
                    return customOperations[operationName]
                        .run(params, request)
                        .then(JSONResponse => {
                            if (JSONResponse[success] === true) {
                                dispatch({ type: actions.CUSTOM_OPERATION_SUCCESS, payload: createPayload(payloadOptions, customOperations[operationName].successed(localState, {
                                    type: actions.CUSTOM_OPERATION_SUCCESS,
                                    payload: JSONResponse[payload],
                                }))});
                                callback(null, JSONResponse[payload]);
                            } else {
                                if (customOperations[operationName].failed) {
                                    dispatch({ type: actions.CUSTOM_OPERATION_FAILED, payload: createPayload(payloadOptions, customOperations[operationName].successed(localState, {
                                        type: actions.CUSTOM_OPERATION_FAILED,
                                        payload: JSONResponse[error],
                                    }))});
                                }
                                callback(JSONResponse[error], null);
                            }
                        }).catch(err => callback(err, null));
                }
            }
        }
    }

    return {...methods, ..._customOperations};
}