// import { SESSION_STATE_KEY } from "../../store";

const initialState = {
    user: null,
    token: null,
    error: null,
    is_confirmed: false,
};

export const types = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
    CONFIRM_FAILED: 'CONFIRM_FAILED',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

export default function (state=initialState, action) {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            console.log('PAYLOAD', JSON.parse(atob(action.payload.token.split('.')[1])));
            return {
                ...state,
                user: {
                    ...JSON.parse(atob(action.payload.token.split('.')[1])),
                },
                token: action.payload.token,
                is_confirmed: true,
            };
            break;
        case types.LOGIN_FAILED:
            return {
                ...state,
                is_confirmed: false,
            };
            break;
        case types.CONFIRM_SUCCESS:
            return {
                ...state,
                is_confirmed: true,
            };
            break;
        case types.CONFIRM_FAILED:
            return {
                ...state,
                is_confirmed: false,
            };
            break;
        case types.LOGOUT_SUCCESS: 
            return {
                ...state,
                is_confirmed: false,
                user: null,
                token: null,
            };
            break;
        default:
            return state;
            break;
    }
}