import React, { useEffect } from 'react';
import Header from '../components/Headers/Header';
import Button from '../components/ui/Buttons';
import Text from '../components/ui/Text';
import { useCart } from '../components/Carts/useCart';
import { useAuth } from '../components/Auth/useAuth';
import { Container, Row, Col } from 'react-bootstrap';
import { navigate } from '../routes';
import OrderSynthese from '../components/Orders/OrderSynthese';
import Page from '../components/Page/Page';
import useTranslations from '../components/Translations/useTranslations';
import BrandLogo from '../components/ui/BrandLogo';

function FinishOrderPage() {

    const [{user}] = useAuth();
    const [{current_cart}, cartSDK] = useCart(user.id);
    const trl = useTranslations();

    useEffect(() => {
        window.addEventListener('popstate', _handleGoBack);

        // Cleaning
        return () => {
            window.removeEventListener('popstate', _handleGoBack);
        }
    }, []);

    const _handleGoBack = e => {
        e.preventDefault();

        if (window.confirm(trl.message('FinishOrderPage.backAction'))) {
            cartSDK.removeOrderHeader({creator: user.id});
            navigate.goBack();
        }
    }

    return (
        <Page>
            <Header
                fixed={true}
                left_pane={(
                    <div className='d-flex align-items-center'>
                        <BrandLogo />
                        <Button
                            size='mini'
                            icon='arrow-left'
                            onClick={_handleGoBack}
                        />
                        <Text variant='title_2' className='ml-3'>
                            {trl.message('FinishOrderPage.title')}
                        </Text>
                    </div>
                )}
            />
            <Header />

            <Container>
                <Row className='justify-content-center'>
                    <Col>
                        <OrderSynthese />
                    </Col>
                </Row>
            </Container>
            
        </Page>
    );
}

export default FinishOrderPage;