import React,{ useState } from "react";
import { useCart } from "./useCart";
import { useAuth } from "../Auth/useAuth";
import theme from "../ui/theme";
import Text from "../ui/Text";
import Button from "../ui/Buttons";
import { navigate } from "../../routes";
import { admin } from "../../routes/admin";
import { price, round2 } from "../../utils";
import useTranslations from "../Translations/useTranslations";
import { Box } from "@material-ui/core";
import { getPricesSteps } from "../../utils_price";
import Dialog from "@material-ui/core/Dialog";

function CurrentCart({ disabled_plv_infos = false, ...props }) {
  const [{ user }] = useAuth();
  const [{ current_cart }, cartsMethods] = useCart(user.id);
  const trl = useTranslations();  

  const cart_data = cartsMethods.getCartData();
  const max_mt_plv_offert = cartsMethods.getMaxMtPLVOffert(cart_data);
  const mt_plv_in_cart = Math.round(cart_data.cumul_plv_in_cart * 100) / 100;
  const [openAlert, setOpenAlert] = useState(false);

  const getMtTotal = () => {
    const mt_max_offert = max_mt_plv_offert;
    const mt_total = cart_data.mt_total;
    // Remove packs remise
    const packs = current_cart.packs;

    const toDeduct = packs.reduce(
      (a, b) => (a += b.mt_remise * b.qte_commande),
      0
    );

    return (
      round2(
        mt_total -
          (mt_plv_in_cart >= mt_max_offert ? mt_max_offert : mt_plv_in_cart)
      ) - toDeduct
    );
  };

  const InfoPLV = () => {
    const mt_max_offert = max_mt_plv_offert;

    return (
      <>
        <div className="d-flex flex-column" style={{ color: theme.primary }}>
          <small>
            {trl.message("Cart.PLV.untilAmount", {
              untilAmount: price(max_mt_plv_offert),
            })}
          </small>
          {mt_plv_in_cart > 0 && (
            <small className="mb-2">
              {trl.message("Cart.PLV.spentAndRemainingAmount", {
                spentAmount: price(
                  mt_plv_in_cart >= mt_max_offert
                    ? mt_max_offert
                    : mt_plv_in_cart
                ),
                remainingAmout: price(
                  round2(
                    mt_max_offert - mt_plv_in_cart <= 0
                      ? 0
                      : mt_max_offert - mt_plv_in_cart
                  )
                ),
              })}
            </small>
          )}
          <Button
            inverted
            size="mini"
            label={trl.message("Cart.completeOrderAction")}
            onClick={() => navigate.push(admin.PLV_PAGE)}
          />
        </div>
        <hr />
      </>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          padding: theme.spacing([1.5, 2]),
          flex: 1,
          overflowY: "scroll",
        }}
      >
        <div className="d-flex justify-content-between">
          <div></div>
          <Text
            as="div"
            variant="title"
            style={{
              width: "100%",
              textAlign: "center",
              borderBottom: "3px solid " + theme.primary,
              paddingBottom: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            {trl.message("Cart.title")}
          </Text>
          <div>
            <Button
              className="ml-3"
              size="mini"
              icon="times"
              onClick={() => props.closeCart()}
            />
          </div>
        </div>
        {Object.keys(current_cart.products).reduce(
          (a, b) => (a += current_cart.products[b].length),
          0
        ) <= 0 && (
          <Text style={{ fontStyle: "italic" }}>
            {trl.message("Cart.emptyCart")}
          </Text>
        )}
        {Object.keys(current_cart.products).map((codgen) => (
          <GenCartCard
            key={codgen}
            addInCart={cartsMethods.addInCart}
            codgen={codgen}
            products={current_cart.products[codgen]}
            creator={user.id}
          />
        ))}
      </div>
      {!(
        Object.keys(current_cart.products).reduce(
          (a, b) => (a += current_cart.products[b].length),
          0
        ) <= 0
      ) && (
        <div
          style={{
            padding: theme.spacing([1]),
            borderTop: "1px solid rgba(0, 0, 0, .1)",
          }}
        >
          {!disabled_plv_infos && max_mt_plv_offert > 0 && InfoPLV()}
          <Dialog
              open={openAlert}
              onClose={() => {}}
              maxWidth="md"
            >
              <div style={{padding: "15px"}}><div>Votre commande ne peut être validée. Notre minimum de commande est de {current_cart.client.groupe_tarifaire.MT_MINI_COMMANDE} €.</div>
                <div style={{"marginTop": "10px","display": "flex", "justifyContent": "center"}}>
                    <Button
                    size="small"
                    label={"OK"}
                    
                    onClick={() => 
                      {
                        setOpenAlert(false)
                      }
                    }
                  />
                </div>
              </div>
            </Dialog>
          <div className="d-flex justify-content-between">
            <Button
              size="small"
              label={trl.message("Cart.finishOrderAction")}
              onClick={() => 
                {
                  if (current_cart.client.groupe_tarifaire.MT_MINI_COMMANDE && getMtTotal() < current_cart.client.groupe_tarifaire.MT_MINI_COMMANDE) {
                    setOpenAlert(true)
                  } else {
                    navigate.push(admin.FINISH_ORDER)
                  }
                }
              }
            />
            {cart_data.mt_total > 0 && (
              <div
                className="d-flex justify-content-center align-items-center h-100"
                style={{
                  fontSize: "2rem",
                  color: theme.primary,
                  borderBottom: "1px solid " + theme.primary,
                }}
              >
                <strong>
                  {price(getMtTotal())}{" "}
                  <span
                    style={{ fontSize: "1rem", color: "rgba(0, 0, 0, .7)" }}
                  >
                    {" "}
                    €
                  </span>
                </strong>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export const GenCartCard = ({
  codgen,
  products,
  addInCart,
  creator,
  hideTools = false,
  showPrice = false,
}) => {
  const [{ user }] = useAuth();

  const [{ current_cart }, cartSDK] = useCart(user.id);

  const trl = useTranslations();

  if (products.length === 0) return null;

  const _removeProduct = (codart) => {
    if (!window.confirm(trl.message("Cart.removeProductOnAction"))) return;
    addInCart({
      creator,
      product: {
        [codgen]: products.filter((prod) => prod.article.CODART !== codart),
      },
    });
  };

  const style = {
    remise: {
      background: "orange",
      color: "white",
      fontSize: ".8rem",
      padding: theme.spacing([0.1, 0.25]),
      borderRadius: ".3em",
      fontWeight: "bold",
      marginRight: ".5rem",
    },
    testeur: {
      background: "fireBrick",
      color: "white",
      fontSize: ".8rem",
      padding: theme.spacing([0.1, 0.25]),
      borderRadius: ".3em",
      fontWeight: "bold",
      marginRight: ".5rem",
    },
  };

  const packArt = current_cart.packs.find((pack) => pack.name === codgen);

  return (
    <div style={{ marginBottom: theme.spacing(1) }}>
      <div className="d-flex justify-content-end">
        {(showPrice || hideTools) && (
          <React.Fragment>
            <Box ml={1} width="150px" textAlign="right">
              <strong style={{ fontSize: ".8rem" }}>
                {trl.message("Cart.productPriceTitle")}
              </strong>
            </Box>
            <Box ml={1} width="150px" textAlign="right">
              <strong style={{ fontSize: ".8rem" }}>
                {trl.message("Cart.orderPriceTitle")}
              </strong>
            </Box>
          </React.Fragment>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <Text as="p" variant="title_2">
          {!!packArt && (
            <strong style={{ fontSize: "1.15rem" }}>
              ({packArt.qte_commande} x){" "}
            </strong>
          )}
          {codgen}
        </Text>
        {!!packArt && (
          <div className="d-flex">
            <div>
              {packArt.mt_remise > 0 && (
                <span style={style.remise}>-{packArt.mt_remise}€</span>
              )}
            </div>
            {hideTools && (
              <Box ml={1} minWidth="150px" textAlign="right">
                <span style={style.remise}>
                  -{price(round2(packArt.mt_remise * packArt.qte_commande))}€
                </span>
              </Box>
            )}
            {!hideTools && (
              <Button
                className="ml-2"
                size="mini"
                inverted
                icon="trash"
                onClick={() => {
                  if (window.confirm(trl.message("Cart.removePackOnAction"))) {
                    cartSDK.removePackFromCart({
                      creator: user.id,
                      packName: packArt.name,
                    });
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div style={{ marginLeft: theme.spacing(1) }}>
        {products.map((product) => {
          const remise =
            product.custom_remise >= 0
              ? product.custom_remise
              : product.article.REMISE_FIXE > 0
              ? product.article.REMISE_FIXE
              : product.article.groupe_tarifaire.REMISE_FIXE > 0
              ? product.article.groupe_tarifaire.REMISE_FIXE
              : null;

          let realPUB =
            product.article.PUB - (product.article.PUB * remise) / 100;

          let totalPrice = realPUB * product.qte_commande;

          if (product.article.prices) {
            const { priceStep, nbUnit } = getPricesSteps(
              product.article,
              product.qte_commande
            );
            realPUB = priceStep.price / nbUnit;
            totalPrice = (priceStep.price * product.qte_commande) / nbUnit;
          }

          const remiseIsOpen =
            product.article.generique.REMISE_OUVERTE === "O" &&
            user.role === "VRP";

          return (
            <div key={product.article.CODART}>
              <div className="d-flex mb-1 justify-content-between">
                <div className="mr-3">
                  <Text variant={"body"}>
                    <strong
                      style={{
                        fontSize: "1.15rem",
                        borderBottom: "1px solid " + theme.primary,
                      }}
                    >
                      {product.qte_commande} x{" "}
                    </strong>

                    {product.article.TESTEUR_ou_PLV === "TES" && (
                      <span style={style.testeur}>
                        {trl.message("MainPage.testerLabel")}
                      </span>
                    )}
                    {remise > 0 && <span style={style.remise}>-{remise}%</span>}

                    {trl.data.message(
                      "ART",
                      "CODART",
                      product.article.CODART,
                      "LIBART"
                    )}
                  </Text>
                </div>
                {!hideTools && !(product.article.generique.TYPGEN === "PACK") && (
                  <div>
                    <span style={{ minWidth: 150, marginRight: ".5rem" }}>
                      {price(round2(totalPrice))}€
                    </span>
                    {remiseIsOpen && (
                      <>
                        <i
                          style={{ color: theme.primary }}
                          className="fa fa-percent"
                        ></i>
                        <input
                          style={{ width: 50, border: "none" }}
                          type="number"
                          defaultValue={remise}
                          onChange={(e) =>
                            cartSDK.customRemise(
                              user.id,
                              trl.data.message(
                                "GEN",
                                "CODGEN",
                                product.article.generique.CODGEN,
                                "LIBGEN"
                              ),
                              product.article.CODART,
                              parseFloat(e.target.value) > 0
                                ? parseFloat(e.target.value) < 100
                                  ? parseFloat(e.target.value)
                                  : 100
                                : 0
                            )
                          }
                        />
                      </>
                    )}
                    <Button
                      size="mini"
                      inverted
                      icon="trash"
                      onClick={() => _removeProduct(product.article.CODART)}
                    />
                  </div>
                )}
                {(showPrice || hideTools) && (
                  <Box display="flex" justifyContent="center">
                    {showPrice && (
                      <div>
                        <strong style={{ fontSize: "1.15rem" }}>
                          {price(round2(realPUB))}€
                        </strong>
                      </div>
                    )}
                    {hideTools && (
                      <Box ml={1} minWidth="150px" textAlign="right">
                        <strong style={{ fontSize: "1.15rem" }}>
                          {price(round2(totalPrice))}€
                        </strong>
                      </Box>
                    )}
                  </Box>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CurrentCart;
