export const formatClientAddress = (client) => {
    const array = [
        client.ADR1,
        client.ADR2,
        client.RUE,
        client.LOC,
        client.CPOSTAL,
        client.VILLE,
        client.PAYS,
    ];

    return array.filter(v => v !== '').join(' ');
}

export const formatAddress = (adr) => {
    const array = [
        adr.NOM,
        adr.ADR1,
        adr.ADR2,
        adr.RUE,
        adr.LOC,
        adr.CPOS,
        adr.VILLE,
        adr.PAYS,
    ];

    return array.filter(v => v !== '').join(' ');
}