import React from 'react';
import theme from '../theme';

function Text ({ variant = 'body', children, as = 'span', style, ...others}) {

    const styles = {
        title: {
            fontSize: theme.spacing(1.25),
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, .6)',
        },
        title_2: {
            fontSize: theme.spacing(1.1),
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, .5)',
        },
        body: {
            fontSize: theme.spacing(1),
            color: 'rgba(0, 0, 0, .7)',
        },
        subtitle: {
            fontSize: theme.spacing(.9),
            color: 'rgba(0, 0, 0, .25)',
        },
        subtitle_dark: {
            fontSize: theme.spacing(.9),
            color: 'rgba(0, 0, 0, .7)',
        },
    };
    
    return React.createElement(as, {style: {...styles[variant], ...style}, ...others}, children);
}

export default Text;