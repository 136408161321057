import ChooseClientPage from "../pages/ChooseClientPage";
import CataloguePage from "../pages/AppNavigation";
import PLVPage from "../pages/PLVPage";
import FinishOrderPage from "../pages/FinishOrderPage";

export const admin = {
    NEW_CART: {
        path: '/orders/clients',
        private: true,
        role: 'VRP',
        component: ChooseClientPage,
    },
    PLV_PAGE: {
        path: '/orders/complete',
        private: true,
        component: PLVPage,
    },
    FINISH_ORDER: {
        path: '/orders/finish',
        private: true,
        component: FinishOrderPage,
    },
    APP_NAVIGATION: {
        path: '/orders/:critere',
        private: true,
        component: CataloguePage,
    },
};