import React, { useEffect } from "react";
import { useAuth } from "../Auth/useAuth";
import theme from "../ui/theme";
import Text from "../ui/Text";
import useTranslations from "../Translations/useTranslations";
import useConfiguration from "../Configuration/useConfiguration";
import { useCart } from "../Carts/useCart";
import { useState } from "react";

const styles = {
  container: {
    backgroundColor: "rgba(0, 0, 0, .05)",
    padding: theme.spacing(1.5),
    borderRadius: 5,
    marginBottom: theme.spacing(1.5),
  },
  textarea: {
    width: "100%",
    border: "none",
    border: "1px solid rgba(0, 0, 0, .1)",
    borderRadius: 5,
  },
  input: {
    border: "none",
    border: "1px solid rgba(0, 0, 0, .1)",
    borderRadius: 5,
  },
};

function ClientRecap({
  current_cart,
  remise,
  createHeader,
  removeOrderHeader,
  frais_port,
}) {
  const [{ user }] = useAuth();
  const [carts, cartSDK] = useCart(user.id);
  const { client, header } = current_cart;

  const trl = useTranslations();
  const config = useConfiguration();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [refcliextension, setRefCliExtension] = useState("");

  const setRefCli = (refcliextension, firstName, lastName) => {
    cartSDK.changeHeader(
      "REF_CLI",
      `Web ${lastName} ${firstName} ${new Date()
        .getDate()
        .toString()
        .padStart(2, "0")}/${new Date().getMonth()} ${refcliextension}`
    );
  };

  useEffect(() => {
    const creator = user.id;
    removeOrderHeader({ creator });
    createHeader({ client, remise, frais_port, creator });
    cartSDK.changeHeader("CODVRP", user.role === "VRP" ? user.id : null);
  }, []);

  if (!header) return null;

  return (
    <div style={styles.container}>
      <div>
        <Text as="p" variant="body">
          {trl.message("FinishOrderPage.Customer.nameLabel")}
          <strong>{header.clientName}</strong>
        </Text>
      </div>
      <div>
        <Text as="p" variant="body">
          {trl.message("FinishOrderPage.Customer.deliveryAddressLabel")}
          <select
            style={styles.input}
            value={header.CODADR_LIV}
            onChange={(e) => cartSDK.changeHeader("CODADR_LIV", e.target.value)}
          >
            <option key={undefined} value={undefined}>
              sélectionnez une adresse
            </option>
            {header.addressesLivraison
              .sort((a, b) => (a.value < b.value ? -1 : 1))
              .map((address) => (
                <option key={address.key} value={address.key}>
                  {address.value}
                </option>
              ))}
          </select>
        </Text>
      </div>
      <div>
        <Text as="p" variant="body">
          {trl.message("FinishOrderPage.Customer.billingAddressLabel")}
          <br />
          <select
            style={styles.input}
            value={header.CODADR_FAC}
            onChange={(e) => cartSDK.changeHeader("CODADR_FAC", e.target.value)}
          >
            <option key={undefined} value={undefined}>
              sélectionnez une adresse
            </option>
            {header.addressFacturation
              .sort((a, b) => (a.value < b.value ? -1 : 1))
              .map((address) => (
                <option key={address.key} value={address.key}>
                  {address.value}
                </option>
              ))}
          </select>
        </Text>
      </div>
      <div>
        <Text as="p" style={styles.title} variant="body">
          {trl.message("FinishOrderPage.Customer.deliveryDateLabel")} *
          <br />
          <input
            style={styles.input}
            type="date"
            value={header.DATE_LIV}
            onChange={(e) => {
              const targetedDate = e.target.value;
              const timestr = "" + config.get("dailyOrderLimitTime");
              const todayAtLimit = new Date();

              const hours = parseInt(timestr.substring(0, 2), 10);
              const min = parseInt(timestr.substring(2, 4), 10);
              const sec = parseInt(timestr.substring(4), 10);

              if (timestr) {
                todayAtLimit.setHours(hours, min, sec);
              } else {
                todayAtLimit.setHours(23, 59, 59);
              }
              if (!header.CODVRP && new Date(targetedDate) < todayAtLimit) {
                let message =
                  "Vous ne pouvez pas choisir une date de départ aujourd'hui.";
                if (timestr) {
                  message +=
                    " L'heure limite pour un départ dans la journée est fixée à " +
                    ("" + hours).padStart(2,"0") +
                    "h" +
                    ("" + min).padStart(2,"0");
                }
                alert(message);
                cartSDK.changeHeader("DATE_LIV", "");

              }
              else {
                  cartSDK.changeHeader("DATE_LIV", targetedDate);
              }
            }}
          />
        </Text>
      </div>
      <div>
        <Text as="p" variant="body">
          {trl.message("FinishOrderPage.Customer.paymentMethod")}
          <br />
          <strong>
            {client.mode_paiement.LIBELLE},{" "}
            {client.paiement_nombre_jours.LIBELLE}
          </strong>
        </Text>
      </div>
      <div>
        <Text as="p" variant="body">
          Nom
          <br />
          <input
            onChange={(e) => {
              setLastName(e.target.value);
              setRefCli(refcliextension, firstName, e.target.value);
            }}
          ></input>
        </Text>
      </div>
      <div>
        <Text as="p" variant="body">
          Prénom
          <br />
          <input
            onChange={(e) => {
              setFirstName(e.target.value);
              setRefCli(refcliextension, e.target.value, lastName);
            }}
          ></input>
        </Text>
      </div>
      { 
      user.data.cmd_facultative !== "O" &&
      <div>
        <Text as="p" variant="body">
          Référence commande
          <br />
          {`Web ${lastName} ${firstName} ${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}/${(new Date().getMonth()+1).toString().padStart(2,"0")} `}
          <input
            onChange={(e) => {
              setRefCliExtension(e.target.value);
              setRefCli(e.target.value, firstName, lastName);
            }}
          ></input>
        </Text>
      </div>
}
      <div>
        <label>
          <Text as="p" variant="boyd">
            {trl.message("FinishOrderPage.Customer.extraInformation")} <br />
            <small>
              {trl.message("FinishOrderPage.Customer.extraInformationDetails")}
            </small>
          </Text>
          <textarea
            onChange={(e) =>
              cartSDK.changeHeader("COMMENTAIRE_LIBRE", e.target.value)
            }
            style={styles.textarea}
            rows={3}
          ></textarea>
        </label>
      </div>
    </div>
  );
}

export default ClientRecap;
