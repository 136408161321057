import React from 'react';
import theme from './theme';

export default function({ p, m, jc, ai, children, ...props}) {

    const style = {
        display: 'flex',
        padding: theme.spacing(p || 0),
        margin: theme.spacing(m || 0),
        justifyContent: jc || 'flex-start',
        alignItems: ai || 'flex-start',
        ...props.style||{},
    }
    
    return (
        <div {...props} style={style}>
            {children}
        </div>
    );
}