import { useDispatch, useSelector } from "react-redux";
import { types } from './reducer';

export function useAuth() {

    const dispatch = useDispatch();
    
    const { auth } = useSelector(state => ({
        auth: state.auth,
    }));

    const methods = {
        logout: () => {
            localStorage.clear();
            dispatch({type: types.LOGOUT_SUCCESS});
        }
    }
    

    return [auth, methods];
}